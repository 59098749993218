import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Privacy.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UserService from '../../Services/user-services'
import Loader from '../Loader/Loader'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import AuthService from '../../Services/auth-services'
class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader:false,
      name: 'React',
      data: "",
      value:"",
      id:0,
      sucessPopup: false,
      sucessClass:true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    UserService.Privacy().then(
      response => {
      console.log(response)
      if(response.data.status === 200){
        this.setState({
          value:response.data.data.title,
          id:response.data.data.id
          
        },
      
        this.setState({
          loader:false
        },()=>{
          console.log("Value==>",this.state.value)
        })
        )
      }else{
        this.setState({
          loader:true
        })
      }
      },
      error => {
        this.setState({
          loader: true,
        },()=>{
          if(error.request.status === 401){
            AuthService.logout();
            return  window.location.href = '/Login';
          }
        })
      }
    );

  }
  handleChange(html) {
    this.setState({ value: html },()=>{
      console.log("HTMLVALUE==>",this.state.value)
    });
  }
  handleSubmit (){
    
    let params = {
      name:this.state.value,
      id:this.state.id
    }
    UserService.EditPrivacy(params).then(
      response => {
      console.log('submit==>',response)
      if(response.data.status === 200){
        this.setState({
          sucessClass:true,
          message:response.data.message,
          sucessPopup:true,
        })
      }else{
        this.setState({
         
          sucessClass:false,
          sucessPopup:true,
          message:"Failed"
        },()=>{
          setTimeout(() => {
            this.setState({
              loader:true,
            })
            // this.props.navigate('/Equipment')
          }, 3000);
          
        })
      }
      },
      error => {
        console.log(error)
        setTimeout(() => {
          this.setState({
            loader:true,
          })
          // this.props.navigate('/Equipment')
        }, 3000);
      }
    );
   
  }
  render() {
    return (
      <div className='Privacymain' >
        {
          this.state.loader === true ?
          <Loader/> :
        <div className='container'>
          <div className='row'>
            <div className={styles.bgWhite}>
              <div className='col-lg-12 my-3'>
                <h3 className='text-center'>Privacy Policy</h3>
              </div>
              <div className='col-lg-12  col-sm-12'>

                <ReactQuill
                  onChange={this.handleChange}
                  className={styles.height}
                  value={this.state.value}
                  scrollingContainer=".parent-scroll"
                />


              </div>
              <div className='privacyBtn'>
                <button className='btn btn-success'
                onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
                 
            </div>
          </div>
          <div className='row'>
            <ToastContainer className="w-100" >
              <Toast style={{ position: "absolute", right: 0 }} onClose={() => {
                this.setState({
                  sucessPopup: false
                })
              }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >
              <Toast.Body className={this.state.sucessClass === true ? styles.sucesss : styles.sucessFailed}>{this.state.message}</Toast.Body>
                {/* <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body> */}
              </Toast>
            </ToastContainer>
            </div>
        </div>
       
      }
      </div>

    );
  }
}

Privacy.propTypes = {};

Privacy.defaultProps = {};

export default Privacy;
