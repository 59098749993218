import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './Brands.module.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UserService from '../../Services/user-services';
import Moment from 'react-moment';
import Pagination from "react-js-pagination";
import Loader from '../Loader/Loader'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import authHeader from '../../Services/auth-header';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AuthService from '../../Services/auth-services'
class Brands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      id: 0,
      pageIndex: 1,
      pageSize: 10,
      pageRange: 3,
      totalRecords: 0,
      loader: false,
      pageRefresh: false,
      searchKey: "",
      form: {
        name: "",
      },
      formErrors: {
        name: "",
      },
      brandList: [],
      content: "",
      setDetail: false,
      sucessPopup: false,
      message: 'failed',
      open: false,
      userId: 0,
      disable: false,
      delete: false,
      btnStatusDisable: false,

    };
  }
  componentDidUpdate() {
    if (this.state.pageRefresh === true) {
      let params = {
        pageIndex: this.state.pageIndex,
        searchKey: this.state.searchKey,
        pageSize: this.state.pageSize
      }
      UserService.bransList(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              brandList: response.data.data.Brands,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              totalRecords: response.data.data.totalRecords,
              loader: true,
              pageRefresh: false
            })
          } else {

          }

        },
        error => {
          console.log(error)
          if(error.request.status === 401){
            AuthService.logout();
            return  window.location.href = '/Login';
          }
        }
      );
    }

  }
  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      let params = {
        pageIndex: this.state.pageIndex,
        searchKey: this.state.searchKey,
        pageSize: this.state.pageSize
      }
      UserService.bransList(params).then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              brandList: response.data.data.Brands,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              totalRecords: response.data.data.totalRecords,
              loader: true
            })
          } else {
            this.setState({
              loader: false
            })
          }

        },
        error => {
          this.setState({
            loader: false
          })
          console.log(error)
        }
      );
    }
  }
  handleClose = () => {
    this.setState({ show: false })
    this.setState({
      form: {
        name: "",
      },
      formErrors: {
        name: "",
      }
    })
  };
  handleShow = () => {
    this.setState({
      show: true,
      form: {
        name: "",
      },
      formErrors: {
        name: "",
      },
    })
  };
  handleChange = e => {
    const value = e.target.value.trimStart()
    const name = e.target.name
    const checked = e.target.checked
    // const { name, value, checked } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};
    if (name === "language") {
      // handle the change event of language field
      if (checked) {
        // push selected value in list
        formObj = { ...form };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...form,
          [name]: form[name].filter(x => x !== value)
        };
      }
    } else {
      // handle change event except language field
      formObj = {
        ...form,
        [name]: value
      };
    }
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };

      this.setState({ formErrors: formErrorsObj });
    });
  };
  validateField = (name, value, refValue) => {
    // console.log('Name-->',value)
    let errorMsg = null;
    switch (name) {
      case "name":
        if (!value) errorMsg = "Please enter Manufacture Name.";
        break;
      default:
        break;
    }
    return errorMsg;
  };
  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
      return errorObj;
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      // console.log(form)

      UserService.addBrands(form).then(
        response => {
          if (response.data.status === 200) {
            console.log("response==>", response)
            this.setState({
              show: false,
              pageRefresh: true,
              form: {
                name: "",
              },
              sucessPopup: true,
              message: response.data.message
            })
          } else {
            this.setState({
              loader: false
            })
          }

        },
        error => {
          this.setState({
            loader: false
          })
          console.log('error===>', error)
        }
      );

    }


    // console.log("Data: ", form);
  };
  handlePageChange = pageNumber => {
    // console.log("Count===>",this.state.count)
    this.setState({
      count: 0
    })

    if (pageNumber !== this.state.pageIndex) {
      let params = {
        pageIndex: pageNumber,
        pageSize: this.state.pageSize
      }
      UserService.bransList(params).then(
        response => {
          console.log(response);


          if (response.data.status === 200) {
            this.setState({
              brandList: response.data.data.Brands,
              totalRecords: response.data.data.totalRecords,
              currentPage: pageNumber,
              recordPerPage: response.data.data.pageSize,
              pageIndex: response.data.data.pageIndex
            }, () => {
            })
          } else {
            this.setState({
              loader: false
            })
          }


        },
        error => {
          this.setState({
            loader: false
          })
        }
      );
    }

  }
  detailClose = () => {

    this.setState({ setDetail: false })

  };
  detailShow = (e) => {

    console.log("Data==>", e)
    let params = {
      id: e
    }
    UserService.GetBrandId(params).then(
      response => {
        console.log("response==>", response)
        if (response.data.status === 200) {

          this.setState({
            form: {
              ...this.state.form, name: response.data.data
                .name
            },
            id: response.data.data.id,
            loader: true,
            // setDetail: false,
            // pageRefresh: true,
          }, () => {
            console.log('UPDATE==>', this.state.form)
          })
        } else {
          this.setState({ loader: false })
        }

      },
      error => {
        this.setState({ loader: false })
      }
    );

    this.setState({ setDetail: true })

  };
  UpdateSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      console.log(form)

      let params = {
        id: this.state.id,
        name: form.name,

      }
      console.log(params)
      UserService.UpdateBrand(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {

            this.setState({
              loader: true,
              pageRefresh: true,
              setDetail: false,
              id: 0,
              sucessPopup: true,
              message: response.data.message
            }, () => {
              console.log('UPDATE==>',)
            })
          } else {
            this.setState({ loader: false })
          }

        },
        error => {
          console.log(error)
          this.setState({ loader: false })
        }
      );

    }


    // console.log("Data: ", form);
  };
  deleteSubmit = () => {
    let params = {
      id: this.state.id
    }
    console.log(params)
    this.setState({
      open: false
    })
    UserService.DeleteBrand(params).then(
      response => {
        console.log("response==>", response)
        if (response.data.status === 200) {

          this.setState({ setDetail: false, errorMsg: "" })
          this.setState({
            form: {
              name: ""
            },
            sucessPopup: true,
            message: response.data.message,
            loader: true,
            pageRefresh: true,
            id: 0

          })
        } else {
          this.setState({ loader: false })
        }

      },
      error => {
        this.setState({ loader: false })
      }
    );

    this.setState({ setDetail: true })

  };
  handleCloseDialog = () => {
    this.setState({
      open: false
    })
  }
  handleOpenDialog = () => {
    this.setState({
      open: true
    })

  }
  // disable

  handleCloseDisable = () => {
    this.setState({
      disable: false
    })
  }
  handleOpenDisbale = (e, disable) => {
    console.log("Disable==>", disable)
    this.setState({
      disable: true,
      userId: e,
      btnStatusDisable: !disable
    })

  }
  handleDisable = () => {
    let params = {
      id: this.state.userId,
      status: this.state.btnStatusDisable
    }
    UserService.DisableManufacture(params).then(
      response => {
        if (response.data.status === 200) {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable: false
          }, () => {
            let params = {
              pageIndex: this.state.pageIndex,
              searchKey: this.state.searchKey,
              pageSize: this.state.pageSize
            }
            UserService.bransList(params).then(
              response => {
                // console.log("response==>", response)
                if (response.data.status === 200) {
                  this.setState({
                    brandList: response.data.data.Brands,
                    pageIndex: response.data.data.pageIndex,
                    pageSize: response.data.data.pageSize,
                    totalRecords: response.data.data.totalRecords,
                    loader: true
                  })
                } else {
                  this.setState({
                    loader: false
                  })
                }

              },
              error => {
                this.setState({
                  loader: false
                })
                console.log(error)
              }
            );


          })
        } else {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable: false
          }, () => {



          })
        }
        // console.log("response==>", response)

      },
      error => {
        console.log(error)
        this.setState({
          isLoader: false,
          sucessPopup: true,
          message: "Server Not Response",
          disable: false
        });
      }
    );
  }

  // delete
  handleCloseDelete = () => {
    this.setState({
      delete: false
    })
  }
  handleOpenDelete = (e) => {

    console.log("VALUE==>", e)
    this.setState({
      delete: true,
      userId: e,

    }, () => {

    })


  }

  handleDelete = () => {
    let params = {
      id: this.state.userId,
    }
    UserService.DeleteBrand(params).then(
      response => {
        if (response.data.status === 200) {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            delete: false
          }, () => {
            let params = {
              pageIndex: this.state.pageIndex,
              searchKey: this.state.searchKey,
              pageSize: this.state.pageSize
            }
            UserService.bransList(params).then(
              response => {
                // console.log("response==>", response)
                if (response.data.status === 200) {
                  this.setState({
                    brandList: response.data.data.Brands,
                    pageIndex: response.data.data.pageIndex,
                    pageSize: response.data.data.pageSize,
                    totalRecords: response.data.data.totalRecords,
                    loader: true
                  })
                } else {
                  this.setState({
                    loader: false
                  })
                }

              },
              error => {
                this.setState({
                  loader: false
                })
                console.log(error)
              }
            );


          })
        } else {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable: false
          }, () => {



          })
        }
        // console.log("response==>", response)

      },
      error => {
        console.log(error)
        this.setState({
          isLoader: false,
          sucessPopup: true,
          message: "Server Not Response",
          disable: false
        });
      }
    );
  }
  render() {
    return (
      <div className='main'>
        {
          this.state.loader === false ?
            <Loader /> :
            <div>
              <div className='row m-0 p-0'>
                <div className='bg-white customer-border rounded-bottom rounded-top m-0 p-0 bg-white'>
                  <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                    <div className=''>
                      <h3 className={"dataHeading"} style={{ margin: '0px', padding: '0px' }}>
                        Manufacture
                      </h3>
                    </div>
                    <div className={'position-relative'}>
                      <span className={styles.plusIcon}>
                        <img src='/imgs/plus.png' className={'plus-icon'} alt=''></img>
                      </span>
                      <button className='btnColorSuccess' onClick={this.handleShow}>Add Manufacture</button>
                    </div>
                  </div>
                  <div>
                    <div className='customtable'>
                      <table className="table tableWidth">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col"> Name</th>
                            <th scope="col">Created Date | Time</th>
                            <th scope="col">Action</th>
                            <th scope="col">Disable</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.brandList.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <th className='border-0' >
                                    {/* {index  * ((this.state.pageIndex*this.state.pageSize) - this.state.pageIndex) } */}

                                    {index + ((this.state.pageSize * this.state.pageIndex) - this.state.pageSize) + 1}
                                  </th>
                                  <td className='border-0' onClick={() => { this.detailShow(data.id) }} style={{ cursor: "pointer" }}>{data.name}</td>
                                  <td className='border-0'>{
                                    <Moment format="MMM-DD-YYYY | HH:mm:ss A" utc={false}>
                                      {data.createdAt}
                                    </Moment>
                                  }</td>
                                  {/* <td className='border-0' onClick={() => { this.detailShow(data.id) }}>
                                    <img src='/imgs/detail.png' alt='' className='imgDetail'></img>
                                  </td>

                                  <td className='border-0'>
                                    <button className={data.isDisable === 1 ? "btn btn-success" : "btn btn-danger"} onClick={() => { this.handleOpenDisbale(data.id, data.isDisable) }} >

                                      {data.isDisable === 1 ? "Enable" : "Disable"}
                                    </button>

                                  </td>
                                  <td className='border-0'>
                                    <button className='btn btn-danger' onClick={() => { this.handleOpenDelete(data.id) }}>
                                      Delete
                                    </button>
                                  </td> */}
                                    <td className='border-0'>
                                    <ul className='navlistIcon'>
                                      <li>
                                        <div className='edit'>
                                          <div onClick={() => { this.detailShow(data.id) }}>
                                            <img src='/imgs/edit.png' alt='' className='img-fluid'></img>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className='delete' onClick={() => { this.handleOpenDelete(data.id) }}>
                                          <img src='/imgs/delete.png' alt='' className='img-fluid'></img>
                                        </div>
                                      </li>
                                     
                                    </ul>
                                  </td>
                                  <td className='border-0'>
                                 
                                        <div className="disableActive" onClick={() => { this.handleOpenDisbale(data.id, data.isDisable) }}   >
                                          {
                                            data.isDisable === 0 ? 
                                            <img src='/imgs/disable.png' alt='' className='img-fluid' ></img>
                                            :
                                            <img src='/imgs/enable.png' alt='' className='img-fluid' ></img>
                                          }
                                          
                                        </div>
                                      
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      <div className='row'>
                        <div className='col-lg-3 m-auto p-2'>
                          {
                            this.state.totalRecords === 0 ?
                              <span className='fw-bold'>No Data Found</span>
                              :
                              <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.pageIndex}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalRecords}
                                pageRangeDisplayed={this.state.pageRange}
                                onChange={this.handlePageChange.bind(this)}
                                activeLinkClass={styles.pageColor}
                              />
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
        }
        <div className='row'>
          <Modal
            show={this.state.setDetail}
            onHide={() => this.setState({ setDetail: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Manufacture</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='container'>
                <div className='row'>
                  <div>
                    <label>Add Manufacture</label>
                    <input type={'text'} className={'form-control'} placeholder={'Please Enter Manufacture'} value={this.state.form.name} name="name" onChange={this.handleChange}
                      onBlur={this.handleChange} />
                    {this.state.formErrors.name && (
                      <span className="text-danger">{this.state.formErrors.name}</span>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={this.handleOpenDialog}>
                Delete
              </Button>
              <Button variant="primary" onClick={this.UpdateSubmit}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.show}
            onHide={() => this.setState({ show: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Please Add Manufacture</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='container'>
                <div className='row'>
                  <div>
                    <label>Add Manufacture</label>
                    <input type={'text'} className={'form-control'} placeholder={'Please Enter Manufacture'} value={this.state.form.name} name="name" onChange={this.handleChange}
                      onBlur={this.handleChange} />
                    {this.state.formErrors.name && (
                      <span className="text-danger">{this.state.formErrors.name}</span>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleSubmit}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
          <ToastContainer className="my-5 mx-2" position={'bottom-end'}>
            <Toast onClose={() => {
              this.setState({
                sucessPopup: false
              })
            }} show={this.state.sucessPopup} delay={1500} autohide  >

              <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
            </Toast>
          </ToastContainer>
          <Dialog
            open={this.state.open}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure delete this manufacture"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={this.handleCloseDialog}>Cancel</Button>
              <Button onClick={this.deleteSubmit} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <div className='disableIcon'>
            <Dialog
              open={this.state.disable}
              onClose={this.handleCloseDisable}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure disable"}
              </DialogTitle>

              <DialogActions>
                <Button onClick={this.handleCloseDisable}>Cancel</Button>
                <Button onClick={this.handleDisable} autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div className='disableDialog'>
          <Dialog
            open={this.state.disable}
            onClose={this.handleCloseDisable}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure disable"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={this.handleCloseDisable}>Cancel</Button>
              <Button onClick={this.handleDisable} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

        </div>
        <div className='deleteDialog'>
          <Dialog
            open={this.state.delete}
            onClose={this.handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure delete"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={this.handleDelete} autoFocus>
                Ok
              </Button>
              <Button onClick={this.handleCloseDelete}>Cancel</Button>
            </DialogActions>
          </Dialog>

        </div>
      </div>
    )
  }
}

Brands.propTypes = {};

Brands.defaultProps = {};

export default Brands;
