import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './viewEquipment.module.scss';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import UserService from '../../Services/user-services'
// import { BsFillArrowUpSquareFill } from "react-icons/bs";
import ProgressBar from 'react-bootstrap/ProgressBar';
// import { AiFillDelete } from "react-icons/ai";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Link } from 'react-router-dom';
import authHeader from '../../Services/auth-header';
import Loader from '../Loader/Loader'
import withRouter from '../../Common/with-router';
import Switch from '@mui/material/Switch';
import AuthService from '../../Services/auth-services'
class ViewEquipment extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      allquipment: 0,
      allboomEquipment: 0,
      advance: 0,
      equipment: [],
      advanceData: [],
      recentProduct: [],
      uploadImage: [],
      completeImageUpload: [],
      imgaeUploadPercantage: 0,
      showIcon: false,
      imageUpload: false,
      isImageUploadDone: true,
      fileName: "",
      isimaageUploadComplete: false,
      imageFormat: true,
      show: false,
      cardImg: "",
      checkboxed: false,
      inputs: {
        name: "",
        amount: '',
        pumpyear: "",
        pumpmake: "",
        pumpmodel: "",
        pumphours: "",
        pumpserialNumber: "",
        pumpsection: "",
        pumpfoldStyle: "",
        pumpradioRemote: "",
        pumpProportional: "",
        pumpcondition: "",
        pumpnotes: "",
        truckyear: "",
        truckbrands: "",
        truckmake: "",
        truckmodel: "",
        truckpumphrs: "",
        truckboomLength: "",
        trucksections: "",
        truckfoldStyle: "",
        truckradioRemote: "",
        truckProptional: "",
        truckSerial: "",
        truckCondition: "",
        trucknotes: "",
      },
      errors: {
        name: "",
        amount: '',
        pumpyear: "",
        pumpmake: "",
        pumpmodel: "",
        pumphours: "",
        pumpserialNumber: "",
        pumpsection: "",
        pumpfoldStyle: "",
        pumpradioRemote: "",
        pumpProportional: "",
        pumpcondition: "",
        pumpnotes: "",
        truckmake: "",
        truckmodel: "",
        truckvin: "",
        truckEngine: "",
        truckHours: "",
        truckmiles: "",
        truckTransmission: "",
        truckmanual: "",
        trucknotes: "",
      },
      btnStatus: false,
      sucessPopup: false,
      message: "failed",
      truckInfoChecked:true




    };
   
  }
  componentDidMount() {
    console.log("Path URl==>", this.props.params.id)
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      UserService.allquipment().then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              equipment: response.data.data.CategoryList,
              // allquipment: response.data.data.CategoryList.length ? response.data.data.CategoryList[0]?.id : ''
            },
              () => {
                console.log("first==>", this.state.allquipment.length)


              })

          } else {
            this.setState({
              isLoader: false
            })
          }
        },
        error => {
          console.log(error)
          this.setState({
            isLoader: false
          });
        }
      );

      UserService.brands().then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              advanceData: response.data.data,
              // advance:response.data.data[0]?.id

            }, () => {

            })
          } else {

          }
        },
        error => {
          console.log(error)
        }
      );
      let params = {
        id: parseInt(this.props.params.id)
      }

      UserService.GetEquipment(params).then(
        response => {
          console.log(response)
          if (response.data.status === 200) {
            this.setState({
              allquipment: response.data.data[0].categoryId,
              advance: response.data.data[0].brandId,
              checkboxed: response.data.data[0].isSold === 1 ? true :false,
              allboomEquipment: response.data.data[0].subCategoryId,
              showIcon:true,
              truckInfoChecked:response.data.data[0].truckInfoStatus === 1 ?true :false
            },
              () => {
                console.log("STATE==>", this.state.checkboxed)
                response.data.data[0].images.map((data, index) => {
                  console.log("ImaageGileName==>",data)
                  const myArray = data.split("Image_").pop().split(".");
                  console.log("MYARRAY==>",myArray)
                  console.log('MAPIMAGES==>',)
                  this.setState((state) => {
                    
                    return {
                      completeImageUpload: [{ uri: data, fileName: myArray[0]+".png", isUpload: 1 }, ...state.completeImageUpload]
                    };
                  })
                  return null
                })
               
                this.setState(prevState => {
                  let inputs = Object.assign({}, prevState.input);  // creating copy of state variable jasper
                  inputs.name = response.data.data[0].name;
                  inputs.amount = response.data.data[0].amount;
                  inputs.pumpyear = response.data.data[0].pumpInfo.year
                  inputs.pumpmake = response.data.data[0].pumpInfo.make
                  inputs.pumpmodel = response.data.data[0].pumpInfo.model
                  inputs.pumphours = response.data.data[0].pumpInfo.hours
                  inputs.pumpserialNumber = response.data.data[0].pumpInfo.serialNumber
                  inputs.pumpsection = response.data.data[0].pumpInfo.section
                  inputs.pumpfoldStyle = response.data.data[0].pumpInfo.foldStyle
                  inputs.pumpradioRemote = response.data.data[0].pumpInfo.radioRemote
                  inputs.pumpProportional = response.data.data[0].pumpInfo.proportional
                  inputs.pumpcondition = response.data.data[0].pumpInfo.Conditions
                  inputs.pumpnotes = response.data.data[0].pumpInfo.notes



                  inputs.truckmake = response.data.data[0].truckInfo.make
                  inputs.truckmodel = response.data.data[0].truckInfo.model
                  inputs.truckvin = response.data.data[0].truckInfo.vin
                  inputs.truckEngine = response.data.data[0].truckInfo.engine
                  inputs.truckHours = response.data.data[0].truckInfo.hours
                  inputs.truckMiles = response.data.data[0].truckInfo.miles
                  inputs.truckTransmission = response.data.data[0].truckInfo.transmission
                  inputs.truckmanual = response.data.data[0].truckInfo.manual
                  inputs.trucknotes = response.data.data[0].truckInfo.notes
                  


                  // : "",

                  return { inputs };
                }, () => {
                  this.setState({
                    isLoader: true
                  })
                })
              }
            )


          } else {
            this.setState({
              isLoader: false
            });
          }
        },
        error => {
          console.log(error)
          this.setState({
            isLoader: false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          });
        }
      )

    }
  }
  handleChange = (event) => {
    console.log('Evv', event.target.value)
    console.log("VALUEOFSETALL==>", this.state.equipment)
    this.state.equipment.forEach((item) => {
      if (item.id === event.target.value) {
        console.log("this is selected item", item);
        this.setState({ allboomEquipment: item.catagoryList[0]?.id }, () => {
          console.log("DATE==>", this.state.allboomEquipment)
        })
      }
    })
    this.setState({ allquipment: parseInt(event.target.value) })

  };
  handleChangeV1 = (event) => {
    console.log('E', event.target.value)

    this.setState({ allboomEquipment: parseInt(event.target.value) },()=>{
      console.log("VALUEOFSETALL==>",this.state.allboomEquipment)
    })


  };
  advanceEquipment = (event) => {
    console.log('E', event.target.value)
    this.setState({ advance: parseInt(event.target.value) })

  };
  onImageChange = event => {
    console.log('PUSH==>', event.target.value)

    let text = event.target.value
    const myArray = text.split("\\").pop().split(".");
    console.log('PUSH==>', myArray[1])
    if (myArray[1] === 'jpg' || myArray[1] === 'png' || myArray[1] === 'jpeg') {
      this.setState({ imageFormat: true })
      console.log("Image Format  Supported")
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        let formData = new FormData();
        this.setState({ btndisable: false })
        formData.append("file", img);
        let params = {
          folder: "Image",
          file: img
        }
        console.log(params)
        if (this.state.completeImageUpload.length > 8) {
          console.log("TENUPLOAD")
          this.setState({ isimaageUploadComplete: true })
        } else {
          console.log("TENUPLOADNOT")
        }
        this.setState({ imageUpload: true, isImageUploadDone: false, fileName: myArray[0] + "." + myArray[1] }, async () => {
          console.log('CALLEDDATA==>', this.state.isImageUploadDone)
          return axios.post
            ('http://3.135.45.164/api/v1//common/uploadFile', params, {

              onUploadProgress: progressEvent =>


                this.setState({ imgaeUploadPercantage: progressEvent.progress * 100 }),
              // console.log('progres----',progressEvent.progress),
              headers: {
                "Content-Type": "multipart/form-data",

              }
            }).then(
              response => {
                console.log('REPONSE==>', response)
                if (response.data.status === 200) {

                  this.setState((state) => {
                    return {
                      completeImageUpload: [{ uri: response.data.data.locationUrl, fileName: myArray[0] + "." + myArray[1], isUpload: 1 }, ...state.completeImageUpload]
                    };
                  }, (data) => {
                    console.log('PUSH==>', this.state.completeImageUpload)
                  })

                  this.setState({ showIcon: true, isImageUploadDone: true })
                  this.setState({ imageUpload: false })
                }
              })
        })


      }
    } else {
      this.setState({ imageFormat: false })
      console.log("Image Format Not Supported")
    }
  }
  deleteImage = (event) => {
    this.setState({ isimaageUploadComplete: false })
    this.setState({ completeImageUpload: this.state.completeImageUpload.filter((data, index) => index !== event) })
  }
  handleClose = () => this.setState({ show: false });
  handleShow = (index) => {
    // console.log(index)
    this.setState({ cardImg: index })
    this.setState({ show: true });
  }

  render() {
    const { errors } = this.state;
    return (
      <div>
        {
          this.state.isLoader === false ?
            <Loader />
            :
            <div className='container-fluid'>
              <div className='row m=0 p-0'>
                <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                  <div className=''>
                    <Link to={'/Equipment'}>


                      {/* <h3 className={ "dataHeading"} style={{margin:'0px',padding:'0px'}}> */}
                      <span className='dataHeading'>
                        <span>
                          <img src='https://placing-booms-content.s3.amazonaws.com/icon/icon_1683009649845.png' className='imgarrow' alt=''></img>
                        </span>
                        Equipment
                      </span>
                    </Link>
                    {/* </h3> */}
                  </div>
                  <div>
                    <Link className='btn btn-success px-4 py-2 text-white' to={'/editEquipment/' + this.props.params.id} >
                        Edit
                    </Link>
                  {/* <button className='btn btn-success px-4 py-2'
                        // onClick={this.handleEdit}
                        disabled={this.state.btnStatus === true ? true : false}
                      >Edit</button> */}
                    </div>
                </div>
              </div>
              <div className='row py-5'>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                        Name
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      <input
                        type={'text'}
                        name="name"
                        className={
                          'form-control'}
                        onChange={event => this.handleUserInput(event)}
                        value={this.state.inputs.name}
                        disabled={true}
                      ></input>
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-sm-12'>
                      <div className='row'>
                        <div className='col-lg-4 col-sm-12 colsLabel'>
                          <label className={styles.labelCatagories}>
                            Category
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          <FormControl
                             disabled={true}
                            className={styles.firstTextbox}
                          >
                            <Select
                              className={styles.colorWhite}
                              value={this.state.allquipment}
                              onChange={this.handleChange}
                              displayEmpty
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    "& .MuiMenuItem-root.Mui-selected": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white',

                                    },
                                    "& .MuiMenuItem-root:hover": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white'
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white'
                                    }
                                  }
                                }
                              }}
                            >
                              {
                                this.state.equipment.map((data, index) => {
                                  // console.log('SUB',data)
                                  return (
                                    <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                   
  <div className='col-lg-6 col-sm-12 m-0 p-0'>
                  <div className='row'>
                    {/* <div className='col-lg-4 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                       Sub Category
                      </label>
                    </div> */}
                    
                    <div>
                      {
                        // this.state.equipment.catagoryList.length===0?"":
                        this.state.equipment.map((item, index) => {
                         
                          return (
                            item.catagoryList.length === 0 ?"":
                            <div key={index}>
                              {
                              
                                item.id === this.state.allquipment ?
                                  <div key={index}>
                                    <div className='row'>
                                    <div className='col-lg-4 col-sm-12 colsLabel'>
                                      <label className={styles.labelCatagories}>
                                      Sub Category
                                      </label>
                                    </div>
                                    <div className='col-lg-6 col-sm-12 '>
                                    <FormControl sx={{ m: 1, minWidth: 120, }}
                                      className={styles.firstTextbox}
                                      disabled={true}
                                    >
                                      <Select
                                        className={styles.colorWhite}
                                        value={this.state.allboomEquipment}
                                        onChange={this.handleChangeV1}
                                        MenuProps={{
                                          PaperProps: {
                                            sx: {
                                              "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white',

                                              },
                                              "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white'
                                              },
                                              "& .MuiMenuItem-root.Mui-selected:hover": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white'
                                              }
                                            }
                                          }
                                        }}
                                        displayEmpty
                                      >
                                        {
                                         
                                          item.catagoryList.map((data, index) => {
                                            // console.log('Equipment inEquipment', data.id)
                                            // this.setState(({
                                            //   allboomEquipment:data.id
                                            // }))
                                            return (
                                              <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                            )
                                          })
                                        }
                                      </Select>
                                    </FormControl>
                                  </div>
                                  </div>
                                  </div>


                                  : ""
                              }
                            </div>
                          )

                          // return <></>;
                        })
                      }
                    </div>
                  </div>
                </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                      Manufacturer
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      <FormControl sx={{ m: 1, minWidth: 120 }}
                        className={styles.firstTextbox}
                        disabled={true}
                      >
                        <Select
                          className={styles.colorWhite}
                          value={this.state.advance}
                          onChange={this.advanceEquipment}
                          displayEmpty
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: "#03C03C",
                                  margin: '0px 10px',
                                  borderRadius: "4px",
                                  color: 'white',

                                },
                                "& .MuiMenuItem-root:hover": {
                                  backgroundColor: "#03C03C",
                                  margin: '0px 10px',
                                  borderRadius: "4px",
                                  color: 'white'
                                },
                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                  backgroundColor: "#03C03C",
                                  margin: '10px 10px',
                                  borderRadius: "4px",
                                  color: 'white'
                                }
                              }
                            }
                          }}
                        >
                          {
                            this.state.advanceData.map((data, index) => {
                              // console.log('Equipment',data)
                              return (
                                <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>

                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                        Price
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      <input
                        type={'text'}
                        name="amount"
                        className={
                          'form-control'}
                        onChange={event => this.handleUserInput(event)}
                        value={this.state.inputs.amount}
                        disabled={true}
                      ></input>
                      <span className="text-danger">{errors.amount}</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                        Sold
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      {/* <input
                        type="checkbox"
                        checked={this.state.checkboxed}
                        ref="complete"
                        onChange={this.handleCheckBox}
                        style={{ margin: "10px 0" }}
                        disabled={true}
                      /> */}
                      <Switch
                        checked={this.state.checkboxed}
                        onChange={this.handleCheckBox}
                        disabled={true}
                        // inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.productdescription}>
                  <hr />
                  <div className='d-flex justify-content-between pb-5'>
              <div>
              <h3 className={styles.mainheading}>
                Specfication
              </h3>
              </div>
              <div>
              <span className={styles.mainheading}>
                Truck Info
              </span>
              <Switch
                        checked={this.state.truckInfoChecked}
                        onChange={this.handleTruckInfoCheckBox}
                        disabled={true}
                        // inputProps={{ 'aria-label': 'controlled' }}
                      />
              </div>
              <div></div>
              <div></div>
            </div>
                    <div className='row justify-content-start'>
                      <div className='col-md-5 m-0 pt-3 col-sm-12'>
                        <div className='row justify-content-start m-2'>
                          <h3 className={styles.descriptionHeading}>
                            Pump Info
                          </h3>
                          <div className='row'>
                            <div className='col-lg-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Make:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpmake"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpmake}

                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpmake}</span>

                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Model:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpmodel"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpmodel}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpmodel}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Year:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpyear"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpyear}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpmodel}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Pump Hours:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumphours"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumphours}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumphours}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Serial Number:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpserialNumber"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpserialNumber}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpserialNumber}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Sections:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpsection"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpsection}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpsection}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Fold Style:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpfoldStyle"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpfoldStyle}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpfoldStyle}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Radio Remote:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpradioRemote"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpradioRemote}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpradioRemote}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Proportional:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpProportional"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpProportional}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpProportional}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Condition:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpcondition"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpcondition}
                                  onChange={this.handleUserInput}
                                ></input>
                                <span className="text-danger">{errors.pumpcondition}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Notes:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpnotes"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.pumpnotes}
                                  onChange={this.handleUserInput}
                                ></input>
                                <span className="text-danger">{errors.pumpnotes}</span>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-5 m-0 pt-3 col-sm-12'>
                        <div className='row justify-content-start m-2'>
                          <h3 className={styles.descriptionHeading}>
                            Truck Info
                          </h3>
                          <div className='row'>
                            <div className='col-lg-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Make:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckmake"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckmake}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckmake}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Model:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>

                                <input
                                  type={'text'}
                                  name="truckmodel"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckmodel}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckmodel}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Vin:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckvin"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckvin}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckvin}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Engine:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckEngine"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckEngine}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckEngine}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Hours:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckHours"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckHours}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckHours}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Miles:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckMiles"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckMiles}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                     
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Transmission:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckTransmission"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckTransmission}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckTransmission}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Manual / Automatic:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckmanual"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.truckmanual}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckmanual}</span>

                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Notes:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="trucknotes"
                                  className={
                                    'form-control'
                                  }
                                  disabled={true}
                                  value={this.state.inputs.trucknotes}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                              
                              </div>
                            </div>
                         
                        
                       
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
                <hr />
                <div className={styles.productdescription}>
                  <h3 className={styles.fileUpload}>
                    Equipment Gallery
                  </h3>
                  <div className='row'>
                    {
                      this.state.isimaageUploadComplete === true ?
                        <div className='col-lg-6 col-sm-12'>

                          <div className={styles.imageborder}>
                            <div className='form-group'>
                              <div className='col-lg-12 imagepadding'>

                                <div className={styles.uploadOuterV1}>
                                  <span className={styles.dragBox} >
                                    Maximum  10 images upload
                                    <img src='/imgs/uploadIcon.png' className={styles.uploadIcon} alt=''></img>
                                    {/* <BsFillArrowUpSquareFill className={styles.uploadIcon} /> */}

                                    <input type="file" 
                                    
                                    onChange={this.onImageChange} disabled={true} />
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className='col-lg-6 col-sm-12'>
                          <div className='text-danger fw-bold text-center'>
                            {
                              this.state.imageFormat === true ? "" : 'Only Image Can Uploaded'
                            }
                          </div>
                          <div className={styles.imageborder}>
                            <div className='form-group'>
                              <div className='col-lg-12 imagepadding'>
                                <div className={styles.uploadOuterV1}>
                                  <span className={styles.dragBox} >
                                    Drag File To upload
                                    <img src='/imgs/uploadIcon.png' className={styles.uploadIcon} alt=''></img>
                                    <input type="file" onChange={this.onImageChange} disabled={true} accept="image/png, image/jpeg" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    }
                    <div className='col-lg-6 col-sm-12'>
                      <div className='form-group'>
                        <div className='col-lg-12 imagepadding'>
                          {/* <h5 className='fw-bold'>File Upload Status</h5> */}
                          <div className='row'>
                            {
                              this.state.isImageUploadDone === true ? "" :
                                <div className='col-lg-12 p-3' >
                                  <div className='row'>
                                    <div className='col-lg-2 col-sm-3 border '>
                                    </div>
                                    <div className='col-lg-8 col-sm-7'>
                                      <span>{this.state.fileName}</span>
                                      <ProgressBar animated now={this.state.imgaeUploadPercantage} className={styles.imageProgress} />
                                      <span>{this.state.imgaeUploadPercantage.toFixed(0)}%</span>
                                    </div>
                                  </div>
                                </div>

                            }
                          </div>
                          <div className='row'>
                            {
                              this.state.completeImageUpload.map((data, index) => {
                                console.log('INDEX==>', index)
                                // console.log('Equipment',data)
                                return (
                                  <div className='col-lg-12 p-3' key={index}>
                                    <div className='row'>
                                      <div className='col-lg-2 col-sm-3 '>
                                        <img src={data.uri} className={styles.imgWidth} alt='' onClick={() => this.handleShow(data.uri)} />
                                      </div>
                                      <div className='col-lg-8 col-sm-7'>
                                        <span>{data.fileName}</span>
                                        <ProgressBar variant='danger' now={100} className={styles.imageProgress} />
                                        <span>Completed</span>
                                      </div>
                                      {/* <div className='col-lg-2 col-sm-3 imgDeleteIcon'>
                                        <span>
                                          {

                                            this.state.showIcon === true ? <AiFillDelete onClick={() => this.deleteImage(index)} className={styles.closeIcon} /> : ""
                                          }
                                        </span>
                                      </div> */}
                                    </div>
                                  </div>
                                )
                              })
                            }
                            <Modal show={this.state.show} onHide={this.handleClose}

                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <Modal.Header closeButton>

                                <Modal.Title className={styles.popuptitle}>IMAGE </Modal.Title>

                              </Modal.Header>

                              <Modal.Body className={styles.popupMessage}>

                                <img src={this.state.cardImg} alt='' className='w-100'></img>
                              </Modal.Body>



                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                {/* <div className='row'>
                  <div className='d-flex justify-content-center m-2'>
                    <div style={{ padding: "0px 40px 0px 0px" }}>
                      <button className='btn btn-success px-4 py-2'
                        onClick={this.handleEdit}
                        disabled={this.state.btnStatus === true ? true : false}
                      >Edit</button>
                    </div>
                    <div>
                      <button className='btn btn-danger px-4 py-2'
                        onClick={this.handleDelete}

                      >Delete</button>
                    </div>
                  </div>
                </div> */}
                <div className='row'>

                  <ToastContainer className="w-100" >
                    <Toast style={{ position: "absolute", right: 0 }} onClose={() => {
                      this.setState({
                        sucessPopup: false
                      })
                    }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >

                      <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                </div>
              </div>

            </div>

        }
      </div>
    )
  }
}


ViewEquipment.propTypes = {};

ViewEquipment.defaultProps = {};

export default  withRouter(ViewEquipment);
