import React from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
 
const withRouter = WrappedComponent => props => {
  const params = useParams();
  let navigate = useNavigate();
  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate ={navigate}
      // router={{ location, navigate, params }} 
    />

  );
};
 
export default withRouter;