import React from 'react';
// import PropTypes from 'prop-types';
import styles from './Loader.module.scss';

const Loader = () => (
  <div className='container-fluid'>
    <div className='bg-layout' >
      <div className="d-flex justify-content-center align-items-center " style={{'height' : '100vh'}}>
    <div className={styles.loader}></div>
      </div>
    </div>
    
    </div>
);


Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;
