import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from '../addEquipment/addEquipment.module.scss';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import UserService from '../../Services/user-services'
// import { BsFillArrowUpSquareFill } from "react-icons/bs";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { AiFillDelete } from "react-icons/ai";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Link } from 'react-router-dom';
import authHeader from '../../Services/auth-header';
import withRouter from '../../Common/with-router';
import Switch from '@mui/material/Switch';
import AuthService from '../../Services/auth-services'
class AddEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      sucessClass:true,
      allquipment: 0,
      allboomEquipment: 0,
      advance: 0,
      equipment: [],
      advanceData: [],
      recentProduct: [],
      uploadImage: [],
      completeImageUpload: [],
      imgaeUploadPercantage: 0,
      showIcon: false,
      imageUpload: false,
      isImageUploadDone: true,
      fileName: "",
      isimaageUploadComplete: false,
      imageFormat: true,
      show: false,
      cardImg: "",
      checkboxed: false,
      isPrority:false,
      inputs: {
        name: "",
        amount: '',
        pumpyear: "",
        pumpmake: "",
        pumpmodel: "",
        pumphours: "",
        pumpSerial: "",
        pumpSection: "",
        pumpFoldStyle: "",
        pumpRadioRemote: "",
        pumpProportional:"",
        pumpcondition: "",
        pumpnotes: "",
   
        truckmake: "",
        truckmodel: "",
        truckvin: "",
        truckEngine: "",
        truckHours: "",
        truckmiles: "",
        truckradioRemote: "",
        truckProptional: "",
        trucktransmission: "",
        truckManual: "",
        trucknotes: "",
      },
      errors: {
        name: "",
        amount: '',
        pumpyear: "",
        pumpmake: "",
        pumpmodel: "",
        pumphours: "",
        pumpSerial: "",
        pumpSection: "",
        pumpFoldStyle: "",
        pumpRadioRemote: "",
        pumpProportional:"",
        pumpcondition: "",
        pumpnotes: "",
      
       
        truckmake: "",
        truckmodel: "",
        truckvin: "",
        truckEngine: "",
        truckHours: "",
        truckmiles: "",
        truckradioRemote: "",
        truckProptional: "",
        trucktransmission: "",
        truckManual: "",
        trucknotes: "",

      },
      btnStatus: false,
      sucessPopup: false,
      message: "failed",
      truckInfoChecked:true




    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      UserService.allquipment().then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              equipment: response.data.data.CategoryList,
              allquipment: response.data.data.CategoryList.length ? response.data.data.CategoryList[0]?.id : ''
            },
              () => {
                console.log("first==>", this.state.allquipment.length)
                this.state.allquipment.length === 0 ?
                  this.setState({
                    btnStatus: true
                  })

                  :


                  this.state.equipment[0].catagoryList.length !== 0 ?
                    // console.log("DAtaShow")

                    this.setState({
                      allboomEquipment: this.state.equipment[0].catagoryList[0]?.id
                    })
                    :
                    this.setState({
                      allboomEquipment: 0
                    })

              })

          } else {
            this.setState({
              isLoader:false
            })
          }
        },
        error => {
          console.log(error)
          this.setState({
            isLoader:false
          },
          ()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          }
          );
        }
      );

      UserService.brands().then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              advanceData: response.data.data,
              // advance:response.data.data[0]?.id

            }, () => {
              this.state.advanceData.length === 0 ?
                this.setState({
                  btnStatus: true
                })
                :
                this.setState({
                  advance: response.data.data.length ? response.data.data[0]?.id : ''
                })
            })
          } else {

          }
        },
        error => {
          console.log(error)
        }
      );
    }
  }
  handleChange = (event) => {
    console.log('Evv', event.target.value)
    console.log("VALUEOFSETALL==>", this.state.equipment)
    this.state.equipment.forEach((item) => {
      if (item.id === event.target.value) {
        console.log("this is selected item", item);
        this.setState({ allboomEquipment: item.catagoryList[0]?.id }, () => {
          console.log("DATE==>", this.state.allboomEquipment)
        })
      }
    })
    this.setState({ allquipment: parseInt(event.target.value) })

  };
  handleChangeV1 = (event) => {
    console.log('E', event.target.value)

    this.setState({ allboomEquipment: parseInt(event.target.value) },()=>{
      console.log("VALUEOFSETALL==>",this.state.allboomEquipment)
    })


  };
  advanceEquipment = (event) => {
    console.log('E', event.target.value)
    this.setState({ advance: parseInt(event.target.value) })

  };
  onImageChange = event => {
    console.log('PUSH==>', event.target.value)

    let text = event.target.value
    const myArray = text.split("\\").pop().split(".");
    console.log('PUSH==>', myArray[1])
    if (myArray[myArray.length-1].toLowerCase() === 'jpg' || myArray[myArray.length-1].toLowerCase() === 'png' || myArray[myArray.length-1].toLowerCase() === 'jpeg') {
      this.setState({ imageFormat: true })
      console.log("Image Format  Supported")
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        let formData = new FormData();
        this.setState({ btndisable: false })
        formData.append("file", img);
        let params = {
          folder: "ProductImage",
          file: img
        }
        console.log(params)
        if (this.state.completeImageUpload.length > 8) {
          console.log("TENUPLOAD")
          this.setState({ isimaageUploadComplete: true })
        } else {
          console.log("TENUPLOADNOT")
        }
        this.setState({ imageUpload: true, isImageUploadDone: false, fileName: myArray[0] + "." + myArray[1] }, async () => {
          console.log('CALLEDDATA==>', this.state.isImageUploadDone)
          return axios.post
            ('https://api.concretepumpingdepot.com//api/v1//common/uploadFile', params, {

              onUploadProgress: progressEvent =>


                this.setState({ imgaeUploadPercantage: progressEvent.progress * 100 }),
              // console.log('progres----',progressEvent.progress),
              headers: {
                "Content-Type": "multipart/form-data",

              }
            }).then(
              response => {
                console.log('REPONSE==>', response)
                if (response.data.status === 200) {
                  let filename = response.data.data.locationUrl.split('/').pop()
                  this.setState((state) => {
                    return {
                      completeImageUpload: [{ uri: response.data.data.locationUrl, fileName:filename , isUpload: 1 }, ...state.completeImageUpload]
                    };
                  }, (data) => {
                    console.log('PUSH==>', this.state.completeImageUpload)
                  })

                  this.setState({ showIcon: true, isImageUploadDone: true })
                  this.setState({ imageUpload: false })
                }
              })
        })


      }
    } else {
      this.setState({ imageFormat: false })
      console.log("Image Format Not Supported")
    }
  }
  deleteImage = (event) => {
    this.setState({ isimaageUploadComplete: false })
    this.setState({ completeImageUpload: this.state.completeImageUpload.filter((data, index) => index !== event) })
  }
  handleClose = () => this.setState({ show: false });
  handleShow = (index) => {
    // console.log(index)
    this.setState({ cardImg: index })
    this.setState({ show: true });
  }
  validate = (name, value) => {
    console.log("First Return if==> VALIDATION VALIDATION",value)
    switch (name) {
      case "name":
        if (!value) {
          return "name is Required";
        } else {
          return "";
        }
      case "amount":
        if (!value) {
          return "amount is Required";
        } 
        else if (
          !/^[0-9]\d*(\.\d+)?$/.test(
            value
          )
        ){
          console.log("First Return if==>")
          return "Invalid amount"
        }
        else {
          return "";
        }
      // case "pumpyear":
      //   if (!value) {
      //     return "Pump Year Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpmake":
      //   if (!value) {
      //     return " pumpmake is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpmodel":
      //   if (!value) {
      //     return "pumpmodel is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumphours":
      //   if (!value) {
      //     return "pumphours  is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpSerial":
      //   if (!value) {
      //     return "pumpSerial is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpSection":
      //   if (!value) {
      //     return "pumpSection  is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpFoldStyle":
      //   if (!value) {
      //     return "pumpFoldStyle is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpRadioRemote":
      //   if (!value) {
      //     return "pumpRadioRemote  is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpcondition":
      //   if (!value) {
      //     return "pumpcondition is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpnotes":
      //   if (!value) {
      //     return "pumpnotes  is Required";
      //   } else {
      //     return "";
      //   }
      // case "":
      //   if (!value) {
      //     return " is Required";
      //   } else {
      //     return "";
      //   }
      // case "":
      //   if (!value) {
      //     return "  is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckmake":
      //   if (!value) {
      //     return "truckmake is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckmodel":
      //   if (!value) {
      //     return "truckmodel  is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckvin":
      //   if (!value) {
      //     return "truckvin is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckEngine":
      //   if (!value) {
      //     return "truckEngine  is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckHours":
      //   if (!value) {
      //     return "truckHours is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckmiles":
      //   if (!value) {
      //     return " truckmiles is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckradioRemote":
      //   if (!value) {
      //     return "truckradioRemote is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckProptional":
      //   if (!value) {
      //     return "truckProptional  is Required";
      //   } else {
      //     return "";
      //   }
      // case "trucktransmission":
      //   if (!value) {
      //     return " trucktransmission is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckManual":
      //   if (!value) {
      //     return "truckManual is Required";
      //   } else {
      //     return "";
      //   }
      // case "trucknotes":
      //   if (!value) {
      //     return "trucknotes  is Required";
      //   } else {
      //     return "";
      //   }

      default: {
        return "";
      }
    }
  }

  handleUserInput = e => {
    console.log("First Return if==> IF",e)
    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: this.validate(e.target.name, e.target.value)
      },
      inputs: {
        ...this.state.inputs,
        [e.target.name]: e.target.value.trimStart()
      }
    });
  };

  handleSubmit = e => {
    const { inputs } = this.state;
    e.preventDefault();
    let validationErrors = {};
    Object.keys(inputs).forEach(name => {
      const error = this.validate(name, inputs[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    } else {
      console.log("Image Count==>")
      if (this.state.completeImageUpload.length === 0) {
      
        this.setState({
          sucessClass:false
       
        },()=>{
          this.setState({
            message:"At Least One Image is Uploaded",
            sucessPopup: true,
            
           
          })
        })
      } else {
        let data = {
          pumpInfo: {
            make: inputs.pumpmake,
            model: inputs.pumpmodel,
            year: inputs.pumpyear,
            hours: inputs.pumphours,
            serialNumber: inputs.pumpSerial,
            section: inputs.pumpSection,
            foldStyle: inputs.pumpFoldStyle,
            radioRemote: inputs.pumpRadioRemote,
            proportional:inputs.pumpProportional,
            condition: inputs.pumpcondition,
            notes: inputs.pumpnotes
          },
          truckInfo: {
          
           
            make: inputs.truckmake,
            model: inputs.truckmodel,
            vin: inputs.truckvin,
            engine: inputs.truckEngine,
            hours: inputs.truckHours,
            miles: inputs.truckmiles,
            transmission: inputs.trucktransmission,
            manual: inputs.truckManual,
            notes: inputs.trucknotes,
          },
          amount: inputs.amount,
          name: inputs.name,
          image: this.state.completeImageUpload.map((item) => {
            return item.uri
          }
          ),

          categoryId: this.state.allquipment,
          subCategoryId: this.state.allboomEquipment,
          brandId: this.state.advance,
          isSold: this.state.checkboxed,
          truckInfoChecked:this.state.truckInfoChecked,
          isPrority:this.state.isPrority
        }
        console.log('newPrint---', data)
        UserService.AddEquipment(data).then(
          response => {
            console.log("response==>", response)
            if (response.data.status === 200) {
              this.setState({
                inputs: {
                  name: "",
                  amount: '',
                  pumpyear: "",
                  pumpmake: "",
                  pumpmodel: "",
                  pumphours: "",
                  pumpSerial: "",
                  pumpSection: "",
                  pumpFoldStyle: "",
                  pumpRadioRemote: "",
                  pumpcondition: "",
                  pumpnotes: "",
                
                  
                  truckmake: "",
                  truckmodel: "",
                  truckvin: "",
                  truckEngine: "",
                  truckHours: "",
                  truckmiles: "",
                  truckradioRemote: "",
                  truckProptional: "",
                  trucktransmission: "",
                  truckManual: "",
                  trucknotes: "",
                },
                sucessPopup: true,
                sucessClass:true,
                message: response.data.message
              },()=>{
                setTimeout(() => {
                  this.props.navigate('/Equipment')
                }, 3000);
                // this.props.navigate('/Equipment')
              })
            } else {
              this.setState({
                sucessPopup: true,
                message: response.data.message
              })
            }
          },
          error => {
            console.log(error)
            this.setState({
              sucessPopup: true,
              message: "server not respond"
            })
          }
        );
    
      }
      // window.alert("subit success");
    }

  };
  handleCheckBox = () => {
    this.setState({
      checkboxed: !this.state.checkboxed,
      // subCatBtn:!this.state.subCatBtn
    }, () => {

    });
  }
  handleCheckBoxV1 = () => {
    this.setState({
      isPrority: !this.state.isPrority,
      // subCatBtn:!this.state.subCatBtn
    }, () => {
      console.log("Priority==>",this.state.isPrority)
    });
  }
  handleTruckInfoCheckBox = () => {
    this.setState({
      truckInfoChecked: !this.state.truckInfoChecked,
      // subCatBtn:!this.state.subCatBtn
    }, () => {

    });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className='container-fluid'>
        <div className='row m=0 p-0'>
          <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
            <div className=''>
              <Link to={'/Equipment'}>


                {/* <h3 className={ "dataHeading"} style={{margin:'0px',padding:'0px'}}> */}
                <span className='dataHeading'>
                  <span>
                    <img src='https://placing-booms-content.s3.amazonaws.com/icon/icon_1683009649845.png' className='imgarrow' alt=''></img>
                  </span>
                  Equipment
                </span>
              </Link>
              {/* </h3> */}
            </div>

          </div>
        </div>
        <div className='row py-5'>
          <div className='col-lg-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-2 col-sm-12 colsLabel'>
                <label className={styles.labelCatagories}>
                  Name
                </label>
              </div>
              <div className='col-lg-3 col-sm-12 '>
              <input
                            type={'text'}
                            name="name"
                            className={
                              'form-control'}
                              onChange={event => this.handleUserInput(event)}
                            
                          ></input>
                      <span className="text-danger">{errors.name}</span>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-sm-12'>
              <div className='row'>
                <div className='col-lg-6 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-4 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                        Category
                      </label>
                    </div>
                    <div className='col-lg-6 col-sm-12 '>
                    <FormControl
                              className={styles.firstTextbox}
                            >
                              <Select
                                className={styles.colorWhite}
                                value={this.state.allquipment}
                                onChange={this.handleChange}
                                displayEmpty
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: "#03C03C",
                                        margin: '0px 10px',
                                        borderRadius: "4px",
                                        color: 'white',

                                      },
                                      "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "#03C03C",
                                        margin: '0px 10px',
                                        borderRadius: "4px",
                                        color: 'white'
                                      },
                                      "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: "#03C03C",
                                        margin: '0px 10px',
                                        borderRadius: "4px",
                                        color: 'white'
                                      }
                                    }
                                  }
                                }}
                              >
                                {
                                  this.state.equipment.map((data, index) => {
                                    // console.log('SUB',data)
                                    return (
                                      <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                    )
                                  })
                                }
                              </Select>
                            </FormControl>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-sm-12 m-0 p-0'>
                  <div className='row'>
                    {/* <div className='col-lg-4 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                       Sub Category
                      </label>
                    </div> */}
                    
                    <div>
                      {
                        // this.state.equipment.catagoryList.length===0?"":
                        this.state.equipment.map((item, index) => {
                         
                          return (
                            item.catagoryList.length === 0 ?"":
                            <div key={index}>
                              {
                              
                                item.id === this.state.allquipment ?
                                  <div key={index}>
                                    <div className='row'>
                                    <div className='col-lg-4 col-sm-12 colsLabel'>
                                      <label className={styles.labelCatagories}>
                                      Sub Category
                                      </label>
                                    </div>
                                    <div className='col-lg-6 col-sm-12 '>
                                    <FormControl sx={{ m: 1, minWidth: 120, }}
                                      className={styles.firstTextbox}
                                    >
                                      <Select
                                        className={styles.colorWhite}
                                        value={this.state.allboomEquipment}
                                        onChange={this.handleChangeV1}
                                        MenuProps={{
                                          PaperProps: {
                                            sx: {
                                              "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white',

                                              },
                                              "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white'
                                              },
                                              "& .MuiMenuItem-root.Mui-selected:hover": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white'
                                              }
                                            }
                                          }
                                        }}
                                        displayEmpty
                                      >
                                        {
                                         
                                          item.catagoryList.map((data, index) => {
                                            // console.log('Equipment inEquipment', data.id)
                                            // this.setState(({
                                            //   allboomEquipment:data.id
                                            // }))
                                            return (
                                              <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                            )
                                          })
                                        }
                                      </Select>
                                    </FormControl>
                                  </div>
                                  </div>
                                  </div>


                                  : ""
                              }
                            </div>
                          )

                          // return <></>;
                        })
                      }
                    </div>
                  </div>
                </div>

              </div>
          </div>  
          <div className='col-lg-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-2 col-sm-12 colsLabel'>
                <label className={styles.labelCatagories}>
                  Manufacturer 
                </label>
              </div>
              <div className='col-lg-3 col-sm-12 '>
              <FormControl sx={{ m: 1, minWidth: 120 }}
                            className={styles.firstTextbox}
                          >
                            <Select
                              className={styles.colorWhite}
                              value={this.state.advance}
                              onChange={this.advanceEquipment}
                              displayEmpty
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    "& .MuiMenuItem-root.Mui-selected": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white',

                                    },
                                    "& .MuiMenuItem-root:hover": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white'
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                      backgroundColor: "#03C03C",
                                      margin: '10px 10px',
                                      borderRadius: "4px",
                                      color: 'white'
                                    }
                                  }
                                }
                              }}
                            >
                              {
                                this.state.advanceData.map((data, index) => {
                                  // console.log('Equipment',data)
                                  return (
                                    <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>

              </div>
            </div>
          </div>
          <div className='col-lg-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-2 col-sm-12 colsLabel'>
                <label className={styles.labelCatagories}>
                  Price
                </label>
              </div>
              <div className='col-lg-3 col-sm-12 '>
              <input
                            type={'text'}
                            name="amount"
                            className={
                              'form-control'}
                              onChange={event => this.handleUserInput(event)}
                            
                          ></input>
                      <span className="text-danger">{errors.amount}</span>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-2 col-sm-12 colsLabel'>
                <label className={styles.labelCatagories}>
                  Sold
                </label>
              </div>
              <div className='col-lg-3 col-sm-12 '>
              {/* <input
                                  type="checkbox"
                                  checked={this.state.checkboxed}
                                  ref="complete"
                                  onChange={this.handleCheckBox}
                                  style={{margin:"10px 0"}}
                                />  */}
                                    <Switch
                        checked={this.state.checkboxed}
                        onChange={this.handleCheckBox}
                        // disabled={true}
                        // inputProps={{ 'aria-label': 'controlled' }}
                      />
              </div>
            </div>
          </div>           
          <div className='col-lg-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-2 col-sm-12 colsLabel'>
                <label className={styles.labelCatagories}>
                isPriority
                </label>
              </div>
              <div className='col-lg-3 col-sm-12 '>
            
                                    <Switch
                        checked={this.state.isPrority}
                        onChange={this.handleCheckBoxV1}
                        // disabled={true}
                        // inputProps={{ 'aria-label': 'controlled' }}
                      />
              </div>
            </div>
          </div>  
          <div className={styles.productdescription}>
            <hr />
            <div className='d-flex justify-content-between pb-5'>
              <div>
              <h3 className={styles.mainheading}>
                Specfication
              </h3>
              </div>
              <div>
              <span className={styles.mainheading}>
                Truck Info
              </span>
              <Switch
                        checked={this.state.truckInfoChecked}
                        onChange={this.handleTruckInfoCheckBox}
                        // disabled={true}
                        // inputProps={{ 'aria-label': 'controlled' }}
                      />
              </div>
              <div></div>
              <div></div>
            </div>
              <div className='row justify-content-start'>
                <div className='col-md-5 m-0 pt-3 col-sm-12'>
                  <div className='row justify-content-start m-2'>
                    <h3 className={styles.descriptionHeading}>
                      Pump Info
                    </h3>
                    <div className='row'>
                     
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Make:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpmake"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpmake}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Model:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpmodel"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpmodel}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Year:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpyear"
                            className={
                              'form-control'
                            }

                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpyear}</span>

                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Pump Hours:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumphours"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumphours}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Serial Number:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpSerial"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpSerial}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Sections:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpSection"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpSection}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Fold Style:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpFoldStyle"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpFoldStyle}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Radio Remote:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpRadioRemote"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpRadioRemote}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Proportional:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpProportional"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpProportional}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Condition:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpcondition"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.pumpcondition}</span>
                        </div>

                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Notes:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="pumpnotes"
                            className={
                              'form-control'
                            }
                            onChange={this.handleUserInput}
                          ></input>
                          <span className="text-danger">{errors.pumpnotes}</span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 m-0 pt-3 col-sm-12'>
                  <div className='row justify-content-start m-2'>
                    <h3 className={styles.descriptionHeading}>
                      Truck Info
                    </h3>
                    <div className='row'>
                     
                    
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Make:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="truckmake"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.truckmake}</span>
                        </div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Model:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="truckmodel"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.truckmodel}</span>
                        </div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Vin:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="truckvin"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.truckvin}</span>
                        </div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Engine:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="truckEngine"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.truckEngine}</span>
                        </div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Hours:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="truckHours"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.truckHours}</span>
                        </div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Miles:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="truckmiles"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.truckmiles}</span>

                        </div>
                      </div>
             
                      
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Transmission:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="trucktransmission"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.trucktransmission}</span>
                        </div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Manual / Automatic:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="truckManual"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.truckManual}</span>
                        </div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeaturesheading}>Notes:</div>
                      </div>
                      <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                        <div className={styles.descriptionfeatures}>
                          <input
                            type={'text'}
                            name="trucknotes"
                            className={
                              'form-control'
                            }
                            onChange={event => this.handleUserInput(event)}
                          ></input>
                          <span className="text-danger">{errors.trucknotes}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          <hr />
          <div className={styles.productdescription}>
            <h3 className={styles.fileUpload}>
             Equipment Gallery
            </h3>
            <div className='row'>
              {
                this.state.isimaageUploadComplete === true ?
                  <div className='col-lg-6 col-sm-12'>

                    <div className={styles.imageborder}>
                      <div className='form-group'>
                        <div className='col-lg-12 imagepadding'>

                          <div className={styles.uploadOuterV1}>
                            <span className={styles.dragBox} >
                              Maximum  10 images upload
                              <img src='/imgs/uploadIcon.png' className={styles.uploadIcon} alt=''></img>
                              {/* <BsFillArrowUpSquareFill className={styles.uploadIcon} /> */}

                              <input type="file" onChange={this.onImageChange} disabled={true} />
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className='col-lg-6 col-sm-12'>
                    <div className='text-danger fw-bold text-center'>
                      {
                        this.state.imageFormat === true ? "" : 'Image format not supported'
                      }
                    </div>
                    <div className={styles.imageborder}>
                      <div className='form-group'>
                        <div className='col-lg-12 imagepadding'>
                          <div className={styles.uploadOuterV1}>
                            <span className={styles.dragBox} >
                              Drag File To upload
                              <img src='/imgs/uploadIcon.png' className={styles.uploadIcon} alt=''></img>
                              {/* <input type="file" onChange={this.onImageChange} disabled={this.state.imageUpload} accept="image/png, image/jpeg" /> */}
                              <input type="file" 
                              onChange={this.onImageChange} disabled={this.state.imageUpload} 
                                    onClick={(e)=> {e.currentTarget.value = null}} 
                                    multiple={true}
                                    accept="image/png, image/jpeg"
                                    />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              <div className='col-lg-6 col-sm-12'>
                <div className='form-group'>
                  <div className='col-lg-12 imagepadding'>
                    {/* <h5 className='fw-bold'>File Upload Status</h5> */}
                    <div className='row'>
                      {
                        this.state.isImageUploadDone === true ? "" :
                          <div className='col-lg-12 p-3' >
                            <div className='row'>
                              <div className='col-lg-2 col-sm-3 border '>
                              </div>
                              <div className='col-lg-8 col-sm-7'>
                                <span>{this.state.fileName}</span>
                                <ProgressBar animated now={this.state.imgaeUploadPercantage} className={styles.imageProgress} />
                                <span>{this.state.imgaeUploadPercantage.toFixed(0)}%</span>
                              </div>
                            </div>
                          </div>

                      }
                    </div>
                    <div className='row'>
                      {
                        this.state.completeImageUpload.map((data, index) => {
                          console.log('INDEX==>', index)
                          // console.log('Equipment',data)
                          return (
                            <div className='col-lg-12 p-3' key={index}>
                              <div className='row'>
                                <div className='col-lg-2 col-sm-3 '>
                                  <img src={data.uri} className={styles.imgWidth} alt='' onClick={() => this.handleShow(data.uri)} />
                                </div>
                                <div className='col-lg-8 col-sm-7'>
                                  <span>{data.fileName}</span>
                                  <ProgressBar variant='danger' now={100} className={styles.imageProgress} />
                                  <span>Completed</span>
                                </div>
                                <div className='col-lg-2 col-sm-3 imgDeleteIcon'>
                                  <span>
                                    {

                                      this.state.showIcon === true ? <AiFillDelete onClick={() => this.deleteImage(index)} className={styles.closeIcon} /> : ""
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                      <Modal show={this.state.show} onHide={this.handleClose}

                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>

                          <Modal.Title className={styles.popuptitle}>IMAGE </Modal.Title>

                        </Modal.Header>

                        <Modal.Body className={styles.popupMessage}>

                          <img src={this.state.cardImg} alt='' className='w-100'></img>
                        </Modal.Body>



                      </Modal>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className='row'>
            <div className='col-lg-3 col-sm-12 m-auto p-5'>
              {
                this.state.btnStatus === true ?
                  <button className='btn btn-success'
                    onClick={this.handleSubmit}
                    disabled={true}
                  >Submit</button>
                  : <button className='btn btn-success'
                    onClick={this.handleSubmit}

                  >Submit</button>
              }





            </div>

          </div>
          <div className='row'>

            <ToastContainer className="w-100" >
              <Toast style={{ position: "absolute", right: 0 ,bottom:35 }} onClose={() => {
                this.setState({
                  sucessPopup: false
                })
              }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >
              <Toast.Body className={this.state.sucessClass === true ? styles.sucesss : styles.sucessFailed}>{this.state.message}</Toast.Body>
                {/* <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body> */}
              </Toast>
            </ToastContainer>
          </div>
        </div>

      </div>
    )
  }
}

AddEquipment.propTypes = {};

AddEquipment.defaultProps = {};

export default withRouter(AddEquipment);
