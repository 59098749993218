import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './Category.module.scss';
import { BsFillPlusCircleFill } from "react-icons/bs";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiFillDelete } from "react-icons/ai";
import Loader from '../Loader/Loader'
import UserService from '../../Services/user-services';
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import authHeader from '../../Services/auth-header';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AuthService from '../../Services/auth-services'
class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      show: false,
      subCatagory: "",
      checkboxed: false,
      subCatBtn: false,
      errorMsg: "",
      form: {
        catagories: "",
      },
      formErrors: {
        catagories: "",
      },
      pageIndex: 1,
      pageSize: 10,
      pageRange: 3,
      totalRecords: 0,
      loader: false,
      pageRefresh: false,
      searchKey: "",
      categoryData: [],
      subCatagoryValue: [],
      setDetail: false,
      sucessPopup: false,
      message: "failed",
      open: false,
      userId: 0,
      disable: false,
      delete: false,
      btnStatusDisable: false,

    };



  }


  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      let params = {
        pageIndex: this.state.pageIndex,
        searchKey: this.state.searchKey,
        pageSize: this.state.pageSize
      }
      UserService.categoryList(params).then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              categoryData: response.data.data.Catagories,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              totalRecords: response.data.data.totalRecords,
              loader: true
            })
          } else {
            this.setState({ loader: false })
          }

        },
        error => {
          console.log(error)
          this.setState({
            loader: false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          })
        }
      );
    }
  }
  componentDidUpdate() {
    if (this.state.pageRefresh === true) {
      let params = {
        pageIndex: this.state.pageIndex,
        searchKey: this.state.searchKey,
        pageSize: this.state.pageSize
      }
      UserService.categoryList(params).then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              categoryData: response.data.data.Catagories,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              totalRecords: response.data.data.totalRecords,
              loader: true,
              pageRefresh: false
            })
          } else {
            this.setState({ loader: false })
          }

        },
        error => {
          this.setState({
            loader: false
          })
        }
      );
    }

  }

  handleClose = () => {

    this.setState({ show: false })

  };
  handleShow = () => {

    this.setState({
      show: true,
      form: {
        catagories: "",
      },
      formErrors: {
        catagories: "",
      },
      subCatagoryValue: [],
      checkboxed: false,
      errorMsg: ""
    })

  };
  handleChange = e => {
    const value = e.target.value.trimStart();
    const { name, checked } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};
    if (name === "language") {
      // handle the change event of language field
      if (checked) {
        // push selected value in list
        formObj = { ...form };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...form,
          [name]: form[name].filter(x => x !== value)
        };
      }
    } else {
      // handle change event except language field
      formObj = {
        ...form,
        [name]: value
      };
    }
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      if (name === "password" || name === "confirmPassword") {
        let refValue = this.state.form[
          name === "password" ? "confirmPassword" : "password"
        ];
        const errorMsg = this.validateField(name, value, refValue);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
        if (!errorMsg && refValue) {
          formErrorsObj.confirmPassword = null;
          formErrorsObj.password = null;
        }
      } else {
        const errorMsg = this.validateField(
          name,
          name === "language" ? this.state.form["language"] : value
        );
        formErrorsObj = { ...formErrors, [name]: errorMsg };
      }
      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    // console.log('Name-->',value)
    let errorMsg = null;
    switch (name) {
      case "catagories":
        if (!value) errorMsg = "Please enter Catagories Name.";
        break;
      default:
        break;
    }
    return errorMsg;
  };
  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      // if (x === "password" || x === "confirmPassword") {
      //   refValue = form[x === "password" ? "confirmPassword" : "password"];
      // }
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
      console.log("called")
      return errorObj;
    }

    );
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      console.log(form)
      this.setState({ show: false, errorMsg: "", subCatagoryValue: [], checkboxed: false })
      this.setState({
        form: {
          catagories: ""
        }

      })
      let params = {
        name: form.catagories,
        subCatagory: this.state.subCatagoryValue
      }
      UserService.addCategory(params).then(
        response => {
          console.log("response==>", response)
          this.setState({
            show: false,
            form: {
              catagories: ""
            },
            sucessPopup: true,
            message: response.data.message
          }, () => {
            this.setState({
              pageRefresh: true,
            })
          })
        },
        error => {
          console.log('error===>', error)
        }
      );

    }


    // console.log("Data: ", form);
  };
  handleCheckBox = () => {
    this.setState({
      checkboxed: !this.state.checkboxed,
      subCatagoryValue: [],
      // subCatBtn:!this.state.subCatBtn
    }, () => {
      if (this.state.checkboxed === true) {
        this.setState({
          subCatBtn: true
        })
      } else {
        this.setState({
          subCatBtn: false
        })
      }
      console.log('CEHCKKED', this.state.checkboxed)
    });
  }
  subCatagory = (event) => {
    this.setState({ subCatagory: event.target.value })

    console.log('SubCAtagory', event.target.value)
  }
  deleteImage = (event) => {

    this.setState({
      subCatagoryValue: this.state.subCatagoryValue.filter((data, index) => index !== event),
      // subCatBtn:!this.state.subCatBtn 

    })
    if (this.state.subCatagoryValue.length === 1) {
      this.setState({
        subCatBtn: true
      }, () => {
        console.log("BTN DISABLE++>", this.state.subCatBtn, "==>", this.state.subCatagoryValue.length)
      })
    } else {
      console.log("BTN DISABLE++>", this.state.subCatBtn, "==>", this.state.subCatagoryValue.length)
    }
  }
  addSubCategory = () => {
    console.log("CA")
    if (this.state.subCatagory === "") {
      this.setState({ errorMsg: "Please Add Sub Catgory" })
    } else {


      this.setState((state) => {
        return {
          subCatagoryValue: [{ name: this.state.subCatagory }, ...state.subCatagoryValue]
        };
      }, () => {
        this.setState({
          subCatagory: "",
          errorMsg: "",
          subCatBtn: false
        })
        console.log('DATA==>', this.state.subCatagoryValue)
      })
    }

  }
  handlePageChange = pageNumber => {
    // console.log("Count===>",this.state.count)
    this.setState({
      count: 0
    })

    if (pageNumber !== this.state.pageIndex) {
      let params = {
        pageIndex: pageNumber,
        pageSize: this.state.pageSize
      }
      UserService.categoryList(params).then(
        response => {
          console.log(response);


          if (response.data.status === 200) {
            this.setState({
              categoryData: response.data.data.Catagories,
              totalRecords: response.data.data.totalRecords,
              currentPage: pageNumber,
              recordPerPage: response.data.data.pageSize,
              pageIndex: response.data.data.pageIndex
            }, () => {
            })
          } else {
            this.setState({
              content:
                (response.message) ||
                response.message ||
                response.toString()
            });
          }


        },
        error => {
          this.setState({
            loader: false
          })
        }
      );
    }

  }
  detailClose = () => {

    this.setState({ setDetail: false })

  };
  detailShow = (e) => {
    console.log("Data==>", e)
    let params = {
      id: e
    }
    this.setState({
      form: {
        catagories: "",
      },
      formErrors: {
        catagories: "",
      },
      subCatagoryValue: [],
      checkboxed: false,
      errorMsg: ""
    })


    UserService.CategoryId(params).then(
      response => {
        console.log("response==>", response)
        if (response.data.status === 200) {

          this.setState({
            form: {
              ...this.state.form, catagories: response.data.data.CategoriesList
                .name
            },
            checkboxed: response.data.data.subCatagory.length > 0 ? true : false,
            id: response.data.data.CategoriesList.id,
            subCatagoryValue: response.data.data.subCatagory.map(iteam => {
              return ({ name: iteam.name })
            }),
            loader: true,
            // setDetail: false,
            pageRefresh: true,
          }, () => {
            console.log('UPDATE==>', this.state.subCatagoryValue)
          })
        } else {
          this.setState({ loader: false })
        }

      },
      error => {
        this.setState({ loader: false })
      }
    );

    this.setState({ setDetail: true })

  };
  detail() {
    alert("Details")
  }
  UpdateSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      console.log(form)
      this.setState({ setDetail: false, errorMsg: "", subCatagoryValue: [], checkboxed: false })
      this.setState({
        form: {
          catagories: ""
        }

      })
      let params = {
        id: this.state.id,
        name: form.catagories,
        subCatagory: this.state.subCatagoryValue
      }
      console.log(params)
      UserService.EditCategory(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {

            this.setState({
              loader: true,
              pageRefresh: true,
              id: 0,
              sucessPopup: true,
              message: response.data.message
            }, () => {
              console.log('UPDATE==>',)
            })
          } else {
            this.setState({ loader: false, })
          }

        },
        error => {
          console.log(error)
          this.setState({ loader: false })
        }
      );

    }


    // console.log("Data: ", form);
  };
  deleteSubmit = () => {

    let params = {
      id: this.state.id
    }
    this.setState({
      open: false
    })
    UserService.DeleteCategory(params).then(
      response => {
        console.log("response==>", response)
        if (response.data.status === 200) {

          this.setState({ setDetail: false, errorMsg: "", subCatagoryValue: [], checkboxed: false })
          this.setState({
            form: {
              catagories: ""
            }

          })
          if (response.data.status === 200) {

            this.setState({
              loader: true,
              pageRefresh: true,
              id: 0,
              sucessPopup: true,
              message: response.data.message
            }, () => {
              console.log('DELETE==>',)
            })
          } else {
            this.setState({ loader: false })
          }
        } else {
          this.setState({ loader: false })
        }

      },
      error => {
        this.setState({ loader: false })
      }
    );

    this.setState({ setDetail: true })

  };
  handleCloseDialog = () => {
    this.setState({
      open: false
    })
  }
  handleOpenDialog = () => {
    this.setState({
      open: true
    })

  }

  // disable

  handleCloseDisable = () => {
    this.setState({
      disable: false
    })
  }
  handleOpenDisbale = (e, disable) => {
    console.log("Disable==>", disable)
    this.setState({
      disable: true,
      userId: e,
      btnStatusDisable: !disable
    })

  }
  handleDisable = () => {
    let params = {
      id: this.state.userId,
      status: this.state.btnStatusDisable
    }
    UserService.DisableCategories(params).then(
      response => {
        if (response.data.status === 200) {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable: false
          }, () => {
            let params = {
              pageIndex: this.state.pageIndex,
              searchKey: this.state.searchKey,
              pageSize: this.state.pageSize
            }
            UserService.categoryList(params).then(
              response => {
                // console.log("response==>", response)
                if (response.data.status === 200) {
                  this.setState({
                    categoryData: response.data.data.Catagories,
                    pageIndex: response.data.data.pageIndex,
                    pageSize: response.data.data.pageSize,
                    totalRecords: response.data.data.totalRecords,
                    loader: true
                  })
                } else {
                  this.setState({ loader: false })
                }

              },
              error => {
                console.log(error)
                this.setState({
                  loader: false
                })
              }
            );


          })
        } else {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable: false
          }, () => {



          })
        }
        // console.log("response==>", response)

      },
      error => {
        console.log(error)
        this.setState({
          isLoader: false,
          sucessPopup: true,
          message: "Server Not Response",
          disable: false
        });
      }
    );
  }

  // delete
  handleCloseDelete = () => {
    this.setState({
      delete: false
    })
  }
  handleOpenDelete = (e) => {

    console.log("VALUE==>", e)
    this.setState({
      delete: true,
      userId: e,

    }, () => {

    })


  }

  handleDelete = () => {
    let params = {
      id: this.state.userId,
    }
    UserService.DeleteCategory(params).then(
      response => {
        if (response.data.status === 200) {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            delete: false
          }, () => {
            let params = {
              pageIndex: this.state.pageIndex,
              searchKey: this.state.searchKey,
              pageSize: this.state.pageSize
            }
            UserService.categoryList(params).then(
              response => {
                // console.log("response==>", response)
                if (response.data.status === 200) {
                  this.setState({
                    categoryData: response.data.data.Catagories,
                    pageIndex: response.data.data.pageIndex,
                    pageSize: response.data.data.pageSize,
                    totalRecords: response.data.data.totalRecords,
                    loader: true
                  })
                } else {
                  this.setState({ loader: false })
                }

              },
              error => {
                console.log(error)
                this.setState({
                  loader: false
                })
              }
            );


          })
        } else {
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable: false
          }, () => {



          })
        }
        // console.log("response==>", response)

      },
      error => {
        console.log(error)
        this.setState({
          isLoader: false,
          sucessPopup: true,
          message: "Server Not Response",
          disable: false
        });
      }
    );
  }
  render() {
    return (
      <div className='main'>
        {
          this.state.loader === true ?
            <div className='brandData'>
              <div className='bg-white customer-border rounded-bottom rounded-top m-0 p-0 bg-white'>
                <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                  <div className=''>
                    <h3 className={'dataHeading'} style={{ margin: '0px', padding: '0px' }}>
                      Categories
                    </h3>
                  </div>
                  <div className={'position-relative'}>
                    <span className={''}>
                      <img src='/imgs/plus.png' className={'plus-icon'} alt=''></img>
                    </span>
                    <button className='btnColorSuccess' onClick={this.handleShow}>Add Catagories</button>
                  </div>
                </div>
                <div>
                  <div className='customtable'>
                    <table className="table tableWidth">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col"> Name</th>
                          <th scope="col"> Sub Catagory Count</th>
                          <th scope="col">Created Data</th>
                          <th scope="col">Action</th>
                          <th scope="col">Disable</th>

                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.categoryData.map((data, index) => {
                            // console.log('Equipment',index)
                            return (
                              <tr key={index}>
                                <th className='border-0' >
                                {index + ((this.state.pageSize * this.state.pageIndex) - this.state.pageSize) + 1}
                                </th>
                                <td className='border-0' onClick={() => { this.detailShow(data.id) }} style={{ cursor: "pointer" }} >{data.name}</td>
                                <td className='border-0' >{data.Count}</td>
                                <td className='border-0' >
                                  <Moment format="MMM-DD-YYYY | HH:mm:ss A" utc={false}>
                                    {data.createdAt}
                                  </Moment>
                                </td>
                                {/* <td className='border-0' onClick={() => { this.detailShow(data.id) }}>
                                  <img src='/imgs/detail.png' alt='' className='imgDetail'></img>
                                </td>
                                <td className='border-0'>
                                  <button className={data.isDisable === 1 ? "btn btn-success" : "btn btn-danger"} onClick={() => { this.handleOpenDisbale(data.id, data.isDisable) }} >

                                    {data.isDisable === 1 ? "Enable" : "Disable"}
                                  </button>

                                </td>
                                <td className='border-0'>
                                  <button className='btn btn-danger' onClick={() => { this.handleOpenDelete(data.id) }}>
                                    Delete
                                  </button>
                                </td> */}
                                  <td className='border-0'>
                                    <ul className='navlistIcon'>
                                      <li>
                                        <div className='edit'>
                                          <div onClick={() => { this.detailShow(data.id) }}>
                                            <img src='/imgs/edit.png' alt='' className='img-fluid'></img>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className='delete' onClick={() => { this.handleOpenDelete(data.id) }}>
                                          <img src='/imgs/delete.png' alt='' className='img-fluid'></img>
                                        </div>
                                      </li>
                                     
                                    </ul>
                                  </td>
                                  <td>
                                 
                                        <div className={ "disableActive"} onClick={() => { this.handleOpenDisbale(data.id, data.isDisable) }}   >
                                          {/* <img src='/imgs/disable.png' alt='' className='img-fluid' ></img> */}
                                          {
                                            data.isDisable === 0 ? 
                                            <img src='/imgs/disable.png' alt='' className='img-fluid' ></img>
                                            :
                                            <img src='/imgs/enable.png' alt='' className='img-fluid' ></img>
                                          }
                                        </div>
                                     
                                  </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div className='row'>
                      <div className='col-lg-3 m-auto p-2'>
                        {
                          this.state.totalRecords === 0 ?
                            <span className='fw-bold'>No Data Found</span>
                            :
                            <Pagination
                              // disableInitialCallback={true}
                              itemClass="page-item"
                              linkClass="page-link"
                              // activeClass={styles.disabled}
                              activePage={this.state.pageIndex}
                              itemsCountPerPage={this.state.pageSize}
                              totalItemsCount={this.state.totalRecords}
                              pageRangeDisplayed={this.state.pageRange}
                              onChange={this.handlePageChange.bind(this)}
                              activeLinkClass={styles.pageColor}
                            // hideDisabled={true}
                            // hideNavigation={true}
                            />
                        }
                      </div>
                    </div>
                    <div className='row'>
                      <Modal
                        show={this.state.setDetail}
                        onHide={() => this.setState({ setDetail: false })}

                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Catagories</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='container'>
                            <div className='row'>
                              <div className={styles.modalform}>
                                <label className={'fw-bold'}>Add Catgories</label>
                                <span className='text-danger'>*</span>
                                <input type={'text'} className={'form-control'} placeholder={'Please Enter Catgories'} value={this.state.form.catagories} name="catagories" onChange={this.handleChange}
                                  onBlur={this.handleChange} />
                                {/* <input type='text' className='form-control'></input> */}
                                {this.state.formErrors.catagories && (
                                  <span className="text-danger">{this.state.formErrors.catagories}</span>
                                )}
                              </div>
                              <div className={styles.modalform}>
                                <label>
                                  {
                                    this.state.subCatagoryValue.length > 0 ?
                                      <input
                                        type="checkbox"
                                        checked={this.state.checkboxed}
                                        ref="complete"
                                        onChange={this.handleCheckBox}
                                        disabled={true}
                                        className={styles.paddingChecked}

                                      /> :
                                      <input
                                        type="checkbox"
                                        checked={this.state.checkboxed}
                                        ref="complete"
                                        onChange={this.handleCheckBox}
                                        className={styles.paddingChecked}
                                      />
                                  }
                                  Have SubCategory?
                                </label>
                                {
                                  this.state.checkboxed === true ?
                                    <div>
                                      <div className='d-flex justify-content-between'>
                                        <div className='w-100'>
                                          <input type='text' className='form-control'
                                            placeholder='Please Enter Sub Catagory'
                                            value={this.state.subCatagory}
                                            style={{ paddingTop: '10px' }}
                                            onChange={this.subCatagory} ></input>

                                          <span className='text-danger'>
                                            {
                                              this.state.errorMsg
                                            }
                                          </span>
                                        </div>
                                        <div>
                                          <BsFillPlusCircleFill className={styles.plusIcon} onClick={this.addSubCategory} />
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <table className="table table-striped">
                                          <thead>
                                            <tr>
                                              <th scope="col"> Sub CatgoryName</th>
                                              <th scope="col">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              this.state.subCatagoryValue.map((data, index) => {
                                                console.log('Equipment', index)
                                                return (
                                                  <tr key={index}>
                                                    <td className={index}>
                                                      {data.name}
                                                    </td>
                                                    <td>
                                                      <AiFillDelete onClick={() => this.deleteImage(index)} />
                                                    </td>
                                                  </tr>

                                                )
                                              })
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    : ""
                                }
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="danger" onClick={this.handleOpenDialog}>
                            Delete
                          </Button>
                          {
                            this.state.subCatBtn === false ? <Button variant="primary" onClick={this.UpdateSubmit}>
                              Update
                            </Button> :
                              <Button variant="primary" onClick={this.UpdateSubmit} disabled={true}>
                                Update
                              </Button>
                          }
                        </Modal.Footer>
                      </Modal>

                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <Modal
                  show={this.state.show}
                  onHide={() => this.setState({ show: false })}
                //  size="md"
                //  aria-labelledby="contained-modal-title-vcenter"
                //  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Catagories</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='container'>
                      <div className='row'>
                        <div className={styles.modalform}>
                          <label className={'fw-bold'}>Add Catgories</label>
                          <span className='text-danger'>*</span>
                          <input type={'text'} className={'form-control'} placeholder={'Please Enter Catgories'} value={this.state.form.catagories} name="catagories" onChange={this.handleChange}
                            onBlur={this.handleChange} />
                          {/* <input type='text' className='form-control'></input> */}
                          {this.state.formErrors.catagories && (
                            <span className="text-danger">{this.state.formErrors.catagories}</span>
                          )}
                        </div>

                        <div className={styles.modalform}>
                          <label className={styles.paddingChecked}>
                            {
                              this.state.subCatagoryValue.length > 0 ?
                                <input
                                  type="checkbox"
                                  checked={this.state.checkboxed}
                                  ref="complete"
                                  onChange={this.handleCheckBox}
                                  disabled={true}
                                  className={styles.paddingChecked}
                                /> :
                                <input
                                  type="checkbox"
                                  checked={this.state.checkboxed}
                                  ref="complete"
                                  onChange={this.handleCheckBox}
                                  className={styles.paddingChecked}
                                />
                            }

                            Have SubCategory?
                          </label>
                          {
                            this.state.checkboxed === true ?
                              <div>
                                <div className='d-flex justify-content-between'>
                                  <div className='w-100'>
                                    <input type='text' className='form-control'
                                      placeholder='Please Enter Sub Catagory'
                                      value={this.state.subCatagory}
                                      style={{ paddingTop: '10px' }}
                                      onChange={this.subCatagory} ></input>
                                    <span className='text-danger'>
                                      {
                                        this.state.errorMsg
                                      }
                                    </span>
                                  </div>
                                  <div>
                                    <BsFillPlusCircleFill className={styles.plusIcon} onClick={this.addSubCategory} />
                                  </div>
                                </div>
                                <div className='row'>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope="col"> Sub CatgoryName</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        this.state.subCatagoryValue.map((data, index) => {
                                          console.log('Equipment', index)
                                          return (
                                            <tr key={index}>
                                              <td className={index}>
                                                {data.name}
                                              </td>
                                              <td>
                                                <AiFillDelete onClick={() => this.deleteImage(index)} />
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              : ""
                          }
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    {
                      this.state.subCatBtn === false ? <Button variant="primary" onClick={this.handleSubmit}>
                        Save
                      </Button> :
                        <Button variant="primary" onClick={this.handleSubmit} disabled={true}>
                          Save
                        </Button>
                    }
                  </Modal.Footer>
                </Modal>
                <ToastContainer className="my-5 mx-2" position={'bottom-end'}>
                  <Toast onClose={() => {
                    this.setState({
                      sucessPopup: false
                    })
                  }} show={this.state.sucessPopup} delay={1500} autohide  >

                    <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
                  </Toast>
                </ToastContainer>
                <Dialog
                  open={this.state.open}
                  onClose={this.handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure delete this category"}
                  </DialogTitle>

                  <DialogActions>
                    <Button onClick={this.handleCloseDialog}>Cancel</Button>
                    <Button onClick={this.deleteSubmit} autoFocus>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div className='disableDialog'>
                <Dialog
                  open={this.state.disable}
                  onClose={this.handleCloseDisable}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure disable"}
                  </DialogTitle>

                  <DialogActions>
                    <Button onClick={this.handleCloseDisable}>Cancel</Button>
                    <Button onClick={this.handleDisable} autoFocus>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

              </div>
              <div className='deleteDialog'>
                <Dialog
                  open={this.state.delete}
                  onClose={this.handleCloseDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure delete"}
                  </DialogTitle>

                  <DialogActions>
                    <Button onClick={this.handleDelete} autoFocus>
                      Ok
                    </Button>
                    <Button onClick={this.handleCloseDelete}>Cancel</Button>
                  </DialogActions>
                </Dialog>

              </div>
            </div>
            :
            <Loader />
        }

      </div>
    )
  }
}

Category.propTypes = {};

Category.defaultProps = {};

export default Category;
