
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout/Layout'
import Dashboard from './components/Dashboard/Dashboard';
import Equipment from './components/equipmentList/equipmentList';
import AddEquipment from './components/addEquipment/addEquipment';

import Catagories from './components/Category/Category';
import Brands from './components/Brands/Brands';
import Login from './components/Login/Login'
import EquipmentSpecficationList from './components/EquipmentSpecfication/EquipmentSpecfication';
import EquipmentSpecficationAdd from './components/AddEquipmentSpecfication/AddEquipmentSpecfication';
import EquipmentSpecficationEdit from './components/EquipmentSpecficationEdit/EquipmentSpecficationEdit';
import Privacy from './components/Privacy/Privacy'
import EditEquipment from './components/EditEquipment/EditEquipment'
import ViewEquipment from './components/viewEquipment/viewEquipment'
import Setting from './components/setting/setting'
import Contact from './components/ContactUs/ContactUs'
import ContactDetail from './components/ContactUsDetail/ContactUsDetail'


function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/Login" element={<Login />}></Route>
        <Route path='/' element={<Layout />}>
            <Route path='/' index element={<Dashboard />} />
            <Route path='/Dashboard' index element={<Dashboard />} />
            <Route path='/Equipment' index element={<Equipment />} />
            <Route path='/Brand' index element={<Brands />} />
            <Route path='/Catagories'  index element={<Catagories />} />
            <Route path='/*' index element={<Dashboard />} />
            <Route path='/add-equipment' index element={<AddEquipment/>}/>
            <Route path='/Equipment-specfication' index element={<EquipmentSpecficationList/>}/>
            <Route path='/Add-Equipment-specfication' index element={<EquipmentSpecficationAdd/>}/>
            <Route path='/Edit-Equipment-specfication/:id' index element={<EquipmentSpecficationEdit/>}/>
            <Route path='/Privacy' index element={<Privacy/>}/>
            <Route path='/EditEquipment/:id' index element={<EditEquipment/>}/>
            <Route path='/viewEquipment/:id' index element={<ViewEquipment/>}/>
            <Route path='/setting' index element={<Setting/>}/>
            <Route path='/Contact-Us' index element={<Contact/>}/>
            <Route path='/Contact-detail/:id' index element={<ContactDetail/>}/>
            
            
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
