import React, { Component } from 'react';
import styles from './equipmentList.module.scss';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import UserService from '../../Services/user-services';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Pagination from "react-js-pagination";
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom';
import authHeader from '../../Services/auth-header';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from 'react-bootstrap/Button';
import withRouter from '../../Common/with-router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AuthService from '../../Services/auth-services'
class EquipmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      id: 0,
      pageIndex: 1,
      pageSize: 20,
      pageRange: 3,
      totalRecords: 0,
      loader: false,
      pageRefresh: false,
      searchKey: "",
      form: {
        name: "",
      },
      formErrors: {
        name: "",
      },
      EquipmentList: [],
      content: "",
      setDetail: false,
      sucessPopup: false,
      delete:false,
      disable:false,
      userId:0,
      message: "failed",
      btnStatusDisable:false,
      destinationIndex:0,
      startingIndex:0,
      oldEquipment:[]
  
      

    };
  }
  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      let params = {
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize
      }
      UserService.eqipmentList(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              EquipmentList: response.data.data.EquipmentList,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              loader: true,
              totalRecords: response.data.data.totalRecords,
            })
          } else {
            this.setState({
              loader:false
            });
          }
        },
        error => {
          this.setState({
            loader:false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          });
        }
      );
    }


  }
  handlePageChange = pageNumber => {
    // console.log("Count===>",this.state.count)
    this.setState({
      count: 0
    })

    if (pageNumber !== this.state.pageIndex) {
      let params = {
        pageIndex: pageNumber,
        pageSize: this.state.pageSize
      }
      UserService.eqipmentList(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              EquipmentList: response.data.data.EquipmentList,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,

              totalRecords: response.data.data.totalRecords,
            })
          } else {

          }
        },
        error => {
          this.setState({
            loader:false
          });
        }
      );
    }

  }
  
  handleCloseDelete = ()=>{
    this.setState({
      delete:false
    })
  }
  handleCloseDisable = ()=>{
    this.setState({
      disable:false
    })
  }

  handleOpenDelete  =(e)=>{
    console.log("VALUE==>",e)
      this.setState({
        delete:true,
        userId:e
      })
      
  }
 
  handleOpenDisbale =(e,disable)=>{
    console.log("Disable==>",disable)
      this.setState({
        disable:true,
        userId:e,
        btnStatusDisable:!disable
      })
      
  }
  handleDelete = ()=>{
   
    let params = {
      id: this.state.userId,
    }
    UserService.DeleteEquipment(params).then(
      response => {
        if(response.data.status === 200){
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            delete:false
          },()=>{
           
            let params = {
              pageIndex: this.state.pageIndex,
              pageSize: this.state.pageSize
            }
            UserService.eqipmentList(params).then(
              response => {
                console.log("response==>", response)
                if (response.data.status === 200) {
                  this.setState({
                    EquipmentList: response.data.data.EquipmentList,
                    pageIndex: response.data.data.pageIndex,
                    pageSize: response.data.data.pageSize,
                    loader: true,
                    totalRecords: response.data.data.totalRecords,
                  })
                } else {
                  this.setState({
                    loader:false
                  });
                }
              },
              error => {
                this.setState({
                  loader:false
                });
              }
            );
            
          })
        }else{
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            delete:false
          },()=>{
           
           
            
          })
        }
        // console.log("response==>", response)
       
      },
      error => {
        console.log(error)
        this.setState({
          isLoader: false,
          sucessPopup: true,
          message: "Server Not Respons"
        });
      }
    );
    // alert(this.state.userId)
  }
  handleDisable = ()=>{
    let params = {
      id: this.state.userId,
      status:this.state.btnStatusDisable
    }
    UserService.DisableEquipment(params).then(
      response => {
        if(response.data.status === 200){
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable:false
          },()=>{
           
            let params = {
              pageIndex: this.state.pageIndex,
              pageSize: this.state.pageSize
            }
            UserService.eqipmentList(params).then(
              response => {
                console.log("response==>", response)
                if (response.data.status === 200) {
                  this.setState({
                    EquipmentList: response.data.data.EquipmentList,
                    pageIndex: response.data.data.pageIndex,
                    pageSize: response.data.data.pageSize,
                    loader: true,
                    totalRecords: response.data.data.totalRecords,
                  })
                } else {
                  this.setState({
                    loader:false
                  });
                }
              },
              error => {
                this.setState({
                  loader:false
                });
              }
            );
            
          })
        }else{
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            disable:false
          },()=>{
           
           
            
          })
        }
        // console.log("response==>", response)
       
      },
      error => {
        console.log(error)
        this.setState({
          isLoader: false,
          sucessPopup: true,
          message: "Server Not Response",
          disable:false
        });
      }
    );
  }
//   onDragEnd = e => {
//     console.log("eQUIPmETuPDATE==>",this.state.EquipmentList)
   
//     if(e.destination == null){
    
//       let data = {
//         droppableId : "Table",
//         index:e.source.index
//       }
//       e.destination  = data
//     }
  
// if(e.source.index>e.destination.index){
//   console.log('PARAMS==>',e)
//   console.log('PARAMS==>',this.state.EquipmentList)
  
//   this.setState({
//     destination:e.destination.index,
//     startingIndex:e.source.index
//   },
  
//   ()=>{
//     let startId = this.state.startingIndex
//     let destination = this.state.destination
   
  

  
//         if (!e.destination) {
//           return;
//         }
       
//          const { EquipmentList } = this.state;
//          const sorted = this.reorder(EquipmentList, e.source.index, e.destination.index);
       
       
      
    
          
//          console.log("DRAG==>",e)
       
//         this.setState({
//           EquipmentList: sorted
//         },
//         ()=>{
          
//           let params = {
//             startIndex:this.state.EquipmentList[startId].id,
//             destination:this.state.EquipmentList[destination].id
//           }
//           UserService.updateEQuipmentProirity(params).then(
//             response => {
//               console.log("response==>", response)

//           console.log("PARAMS==>",params)
//         });
//       })
   
//   })
//   console.log("PARAMS==>up")

// }else{
//   this.setState({
//     startingIndex:e.source.index,
//     destination:e.destination.index
//   },
  
//   ()=>{
//     let startId = this.state.startingIndex
//     let destination = this.state.destination
   
//     let params = {
//       startIndex:this.state.EquipmentList[startId].id,
//       destination:this.state.EquipmentList[destination].id
//     }
//     console.log("PARAMS==>",params)

//     UserService.updateEQuipmentProirity(params).then(
//       response => {
//         console.log("response==>", response)
//         if (!e.destination) {
//           return;
//         }
       
//          const { EquipmentList } = this.state;
//          const sorted = this.reorder(EquipmentList, e.source.index, e.destination.index);
       
       
      
    
          
//          console.log("DRAG==>",e)
       
//         this.setState({
//           EquipmentList: sorted
//         },
//         ()=>{
         
//         });
//       })
   
//   })
//   console.log("RAHULdown")
// }
 
 
     
//     // console.log("EquipMetData==>",this.state.EquipmentList)
   
    
     
//     console.log('DRAG==>',e);

    
 
   
//   };
onDragEnd = e => {
  console.log("eQUIPmETuPDATE==>",this.state.EquipmentList)
 
  if(e.destination == null){
  
    let data = {
      droppableId : "Table",
      index:e.source.index
    }
    e.destination  = data
  }




this.setState({
  destination:e.destination.index,
  startingIndex:e.source.index
},

()=>{
  
  let params = {
    startIndex:this.state.EquipmentList[this.state.startingIndex].id,
    destination:this.state.EquipmentList[this.state.destination].id
  }
    console.log("PARAMS==>",params)
  UserService.updateEQuipmentProirity(params).then(
    response => {
      console.log("response==>", response)

});



      if (!e.destination) {
        return;
      }
     
       const { EquipmentList } = this.state;
       const sorted = this.reorder(EquipmentList, e.source.index, e.destination.index);
     
     
    
  
        
       console.log("DRAG==>",e)
     
      this.setState({
        EquipmentList: sorted
      },
      ()=>{
        
      
    })
 
})
// console.log("PARAMS==>up")





   
  // console.log("EquipMetData==>",this.state.EquipmentList)
 
  
   
  console.log('DRAG==>',e);

  

 
};
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  render() {
    return (
      <div className='main'>
        {
          this.state.loader === false ?
            <Loader /> :
            <div>
              <div className='row m-0 p-0'>
                <div className='bg-white customer-border rounded-bottom rounded-top m-0 p-0 bg-white'>
                  <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                    <div className=''>
                      <h3 className={"dataHeading"} style={{ margin: '0px', padding: '0px' }}>
                        Equipment
                      </h3>
                    </div>
                    <div className={'position-relative'}>
                      <span className={styles.plusIcon}>
                        <img src='/imgs/plus.png' className={'plus-icon'} alt=''></img>
                      </span>
                      <Link to={'/add-equipment'} className='btnColorSuccess'>
                        Add Equipment
                      </Link>
                      {/* <button className='btnColorSuccess' onClick={this.handleShow}>Add Equipment</button> */}
                    </div>
                  </div>
                  <div>
                    <div className='customtable'>
                    <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable 
          
          droppableId="Table"
          
          >
            {provided => (
              <table {...provided.droppableProps} ref={provided.innerRef}>
                     <thead>
                          <tr>
                            <th scope="col">#</th>
                         
                            <th scope="col"> Name</th>
                            <th scope="col">Brand Name</th>
                         
                            <th scope="col">Availability Status</th>
                            <th scope="col">Action</th>
                            <th scope="col">Disable</th>
                            
                         
                          </tr>
                        </thead>
                <tbody
                >
                  {this.state.EquipmentList.map((item, index) => (
                    <Draggable
                
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {provided => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <th className='border-0' >
          {index + ((this.state.pageSize * this.state.pageIndex) - this.state.pageSize) + 1}
          </th>
         
          <td className='border-0'>
          <Link to={'/viewEquipment/' + item.id}>
            {item.name}
            </Link>
            </td>
          <td className='border-0'>{item.BrandName}</td>
         
          <td className='border-0' >
            {
              item.isSold === 0 ?
              <div className='soldStatusaccept'>
              <span className='soldSatussucess'>
              Available
              </span>
            </div>
                :
                <div className='soldStatusDecline'>
              <span className='soldSatusFailed'>
              Sold 
              </span>
            </div>
            }

          </td>
          <td className='border-0'>
            <ul className='navlistIcon'>
              <li>
                <div className='edit'>
                  <Link to={'/EditEquipment/' + item.id}>
                    <img src='/imgs/edit.png' alt='' className='img-fluid'></img>
                  </Link>
                </div>
              </li>
              <li>
                <div className='delete' onClick={() => { this.handleOpenDelete(item.id) }}>
                  <img src='/imgs/delete.png' alt='' className='img-fluid'></img>
                </div>
              </li>
             
            </ul>
          </td>
          <td  className='border-0'>
        
                <div className={"disableActive"} onClick={() => { this.handleOpenDisbale(item.id, item.isDisable) }}  >
                  {
                    item.isDisable ===0 ? 
                    <img src='/imgs/disable.png' alt='' className='img-fluid' ></img> :
                    <img src='/imgs/enable.png' alt='' className='img-fluid' ></img>
                  }
                  {/* <img src='/imgs/disable.png' alt='' className='img-fluid' ></img> */}
                </div>
             
          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      </div>
                      <div className='row'>
                        <div className='col-lg-3 m-auto p-2'>
                          {
                            this.state.totalRecords === 0 ?
                              <span className='fw-bold'>No Data Found</span>
                              :
                              <Pagination
                                // disableInitialCallback={true}
                                itemClass="page-item"
                                linkClass="page-link"
                                // activeClass={styles.disabled}
                                activePage={this.state.pageIndex}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalRecords}
                                pageRangeDisplayed={this.state.pageRange}
                                onChange={this.handlePageChange.bind(this)}
                                activeLinkClass={styles.pageColor}
                              // hideDisabled={true}
                              // hideNavigation={true}
                              />
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
        }
        <div className='deleteDialog'>
        <Dialog
          open={this.state.delete}
          onClose={this.handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure delete"}
          </DialogTitle>
      
          <DialogActions>
            <Button onClick={this.handleCloseDelete}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        </div>
        <div className='disableDialog'>
        <Dialog
          open={this.state.disable}
          onClose={this.handleCloseDisable}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {/* {"Are you sure disable"} */}
            {this.state.btnStatusDisable === true ? "Disable this listing?":"Enable this listing?"}
          </DialogTitle>
      
          <DialogActions>
            <Button onClick={this.handleCloseDisable}>Cancel</Button>
            <Button onClick={this.handleDisable} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer className="w-100" >
                    <Toast style={{ position: "absolute", right: 0 }} onClose={() => {
                      this.setState({
                        sucessPopup: false
                      })
                    }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >

                      <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
        </div>
      </div>
    )
  }
}

EquipmentList.propTypes = {};

EquipmentList.defaultProps = {};

export default withRouter(EquipmentList);
