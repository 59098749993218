import React,{Component} from 'react';
// import PropTypes from 'prop-types';
import styles from './ContactUs.module.scss';
import UserService from '../../Services/user-services';
import {AiFillEye } from "react-icons/ai";
import Pagination from "react-js-pagination";
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom';
import authHeader from '../../Services/auth-header';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import withRouter from '../../Common/with-router';
import AuthService from '../../Services/auth-services'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from 'react-bootstrap/Button';
class ContactUs  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      id: 0,
      pageIndex: 1,
      pageSize: 10,
      pageRange: 3,
      totalRecords: 0,
      loader: false,
      pageRefresh: false,
      searchKey: "",
      form: {
        name: "",
      },
      formErrors: {
        name: "",
      },
      ContactList: [],
      content: "",
      setDetail: false,
      sucessPopup: false,
      delete:false,
      disable:false,
      userId:0,
      message: "failed",
      btnStatusDisable:false,
      
      

    };
  }
  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      let params = {
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize
      }
      UserService.contactUs(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              ContactList: response.data.data.Contact,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              loader: true,
              totalRecords: response.data.data.totalRecords,
            })
          } else {
            this.setState({
              loader:false
            });
          }
        },
        error => {
          console.log('ERROR==>',error)
          this.setState({
            loader:false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          });
        }
      );
    }


  }
  handlePageChange = pageNumber => {
    // console.log("Count===>",this.state.count)
    this.setState({
      count: 0
    })

    if (pageNumber !== this.state.pageIndex) {
      let params = {
        pageIndex: pageNumber,
        pageSize: this.state.pageSize
      }
   
      UserService.contactUs(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              ContactList: response.data.data.Contact,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              loader: true,
              totalRecords: response.data.data.totalRecords,
            })
          } else {
            this.setState({
              loader:false
            });
          }
        },
        error => {
          this.setState({
            loader:false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          });
        }
      );
    }

  }
  handleOpenDelete  =()=>{

   
   this.setState({
    delete:false
   },
   ()=>{
    let params =  {
      id:this.state.id
    }
    UserService.DeleteContactUs(params).then(
      response => {
        console.log("response==>", response)
        if (response.data.status === 200) {
          this.setState({
            sucessPopup: true,
               message: response.data.message,
               loader:false
         },
         ()=>{
          let params = {
            pageIndex: this.state.pageIndex,
            pageSize: this.state.pageSize
          }
       
          UserService.contactUs(params).then(
            response => {
              console.log("response==>", response)
              if (response.data.status === 200) {
                this.setState({
                  ContactList: response.data.data.Contact,
                  pageIndex: response.data.data.pageIndex,
                  pageSize: response.data.data.pageSize,
                  loader: true,
                  totalRecords: response.data.data.totalRecords,
                })
              } else {
                this.setState({
                  loader:false
                });
              }
            },
            error => {
              this.setState({
                loader:false
              },()=>{
                if(error.request.status === 401){
                  AuthService.logout();
                  return  window.location.href = '/Login';
                }
              });
            }
          );
         }
         )
        } else {
          this.setState({
            loader:false
          });
        }
      },
      error => {
        this.setState({
          loader:false
        },()=>{
          if(error.request.status === 401){
            AuthService.logout();
            return  window.location.href = '/Login';
          }
        });
      }
    );
    console.log("Params==>",params)
   }
   )
   
  }
  handleShowOpenDelete = (id)=>{
    console.log("Id==>",id)
    this.setState({
      delete:true,
      id:id
    },
    ()=>{
      console.log("Value==>")
    })
  }
  handleCloseDelete = ()=>{
    this.setState({
      delete:false
    })
  }
  render() {
    return (
      <div className='main'>
        {
          this.state.loader === false ?
            <Loader /> :
            <div>
              
              <div className='row m-0 p-0'>
                <div className='bg-white customer-border rounded-bottom rounded-top m-0 p-0 bg-white'>
                  <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                    <div className=''>
                      <h3 className={"dataHeading"} style={{ margin: '0px', padding: '0px' }}>
                        Contact Us
                      </h3>
                    </div>
                  
                  </div>
                  <div>
                    <div className='customtable'>
                      <table className="table tableWidth">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col"> First Name</th>
                            <th scope="col">Last Name</th>
                         
                            <th scope="col">Email</th>
                            <th scope="col">Action</th>
                           
                         
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.ContactList.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <th className='border-0' >
                                  {index + ((this.state.pageSize * this.state.pageIndex) - this.state.pageSize) + 1}
                                  </th>
                                  <td className='border-0'>
                               
                                    {data.firstName}
                                  
                                    </td>
                                  <td className='border-0'>{data.lastName}</td>
                                  <td className='border-0'>{data.email}</td>
                                  
                                  <td className='border-0'>
                                    <ul className='navlistIcon'>
                                      <li>
                                        <div className='edit'>
                                          <Link to={'/Contact-detail/' + data.id}>
                                            <AiFillEye className='viewDetail'/>
                                            {/* <img src='/imgs/edit.png' alt='' className='img-fluid'></img> */}
                                          </Link>
                                        </div>
                                      </li>
                                      <li>
                                        <div className='delete' onClick={() => { this.handleShowOpenDelete(data.id) }}>
                                          <img src='/imgs/delete.png' alt='' className='img-fluid'></img>
                                        </div>
                                      </li>
                                    
                                    </ul>
                                  </td>
                                  
                                
                                      
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      <ToastContainer className="w-100" >
                    <Toast style={{ position: "absolute", right: 0,   }} onClose={() => {
                      this.setState({
                        sucessPopup: false
                      })
                    }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >

                      <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                      <div className='row'>
                        <div className='col-lg-3 m-auto p-2'>
                          {
                            this.state.totalRecords === 0 ?
                              <span className='fw-bold'>No Data Found</span>
                              :
                              <Pagination
                                // disableInitialCallback={true}
                                itemClass="page-item"
                                linkClass="page-link"
                                // activeClass={styles.disabled}
                                activePage={this.state.pageIndex}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalRecords}
                                pageRangeDisplayed={this.state.pageRange}
                                onChange={this.handlePageChange.bind(this)}
                                activeLinkClass={styles.pageColor}
                              // hideDisabled={true}
                              // hideNavigation={true}
                              />
                          }
                        </div>
                      </div>
                    </div>
                    <Dialog
          open={this.state.delete}
          onClose={this.handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure delete"}
          </DialogTitle>
      
          <DialogActions>
            <Button onClick={this.handleCloseDelete}>Cancel</Button>
            <Button onClick={this.handleOpenDelete} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
                  </div>
                </div>
              </div>
            </div>
        }
        
      </div>
    )
  }
}

ContactUs.propTypes = {};

ContactUs.defaultProps = {};

export default withRouter(ContactUs);
