import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ContactUsDetail.module.scss';
import authHeader from '../../Services/auth-header';
import withRouter from '../../Common/with-router';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';
import UserService from '../../Services/user-services';
import AuthService from '../../Services/auth-services'
class ContactUsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      firstName: "",
      lastName: "",
      pumpId: "",
      email: "",
      phoneNumber: "",
      message: ""




    };

  }
  componentDidMount() {
    console.log("Path URl==>", this.props.params.id)
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {

      let params = {
        id: parseInt(this.props.params.id)
      }
      UserService.contactUsDetail(params).then(
        response => {
          if (response.data.status === 200) {
            this.setState({
              firstName: response.data.data.firstName,
              lastName: response.data.data.lastName,
              email: response.data.data.email,
              phoneNumber: response.data.data.mobile,
              pumpId: response.data.data.pumpId,
              message: response.data.data.message,
              isLoader: true
            })
          } else {
            this.setState({
              isLoader: false
            })
          }
        },
        error => {
          console.log(error)
          this.setState({
            isLoader: false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          });
        }
      );




    }
  }
  render() {
    return (
      <div className='main'>
        {
          this.state.isLoader === false ?
            <Loader />
            :
            <div className='container-fluid'>
              <div className='row'>
                <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                  <div className=''>
                    <Link to={'/Contact-Us'}>


                      {/* <h3 className={ "dataHeading"} style={{margin:'0px',padding:'0px'}}> */}
                      <span className='dataHeading'>
                        <span>
                          <img src='https://placing-booms-content.s3.amazonaws.com/icon/icon_1683009649845.png' className='imgarrow' alt=''></img>
                        </span>
                        Contact Us
                      </span>
                    </Link>
                    {/* </h3> */}
                  </div>
                </div>
                {/*                 
                <div className='row'>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className="row ">
                      <div className='col-lg-4 col-sm-12 '>
                        <label>
                          First Name
                        </label>
                      </div>
                      <div className='col-lg-6 col-sm-12 '>
                        <input type='text' className='form-control' value={this.state.firstName} disabled={true}></input>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className="row ">
                      <div className='col-lg-4 col-sm-12 '>
                        <label>
                          Last Name
                        </label>
                      </div>
                      <div className='col-lg-6 col-sm-12 '>
                        <input type='text' className='form-control' value={this.state.lastName} disabled={true}></input>
                      </div>
                    </div>
                  </div>
              
                </div>
                <div className='row'>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className="row ">
                      <div className='col-lg-4 col-sm-12 '>
                        <label>
                         Email
                        </label>
                      </div>
                      <div className='col-lg-6 col-sm-12 '>
                        <input type='text' className='form-control' value={this.state.email} disabled={true}></input>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className="row ">
                      <div className='col-lg-4 col-sm-12 '>
                        <label>
                          Phone Number
                        </label>
                      </div>
                      <div className='col-lg-6 col-sm-12 '>
                        <input type='text' className='form-control' value={this.state.phoneNumber} disabled={true}></input>
                      </div>
                    </div>
                  </div>
              
                </div>
                <div className='row '>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className="row ">
                      <div className='col-lg-4 col-sm-12 '>
                        <label>
                         Pump Id
                        </label>
                      </div>
                      <div className='col-lg-6 col-sm-12 '>
                        <input type='text' className='form-control' value={this.state.pumpId} disabled={true}></input>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    
                  </div>
              
                </div>
                <div className='row'>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className="row ">
                      <div className='col-lg-4 col-sm-12 '>
                        <label>
                         Message
                        </label>
                      </div>
                      <div className='col-lg-6 col-sm-12 '>
                        <textarea  type="text" className='form-control' value={this.state.message} disabled={true}></textarea >
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    
                  </div>
              
                </div> */}
                <div className='row '>

                  <div className='col-lg-6 col-sm-12 '>
                    <div className={styles.padding}>
                      <div className="row ">
                        <div className='col-lg-4 col-sm-12 '>
                          <label  className='fw-bold'>
                            First Name
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          {
                            this.state.firstName
                          }
                          {/* <input type='text' className='form-control' value={this.state.firstName} disabled={true}></input> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className={styles.padding}>
                      <div className="row ">
                        <div className='col-lg-4 col-sm-12 '>
                          <label  className='fw-bold'>
                            Last Name
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          <label>
{
  this.state.lastName
}
                          </label>
                          {/* <input type='text' className='form-control' value={this.state.lastName} disabled={true}></input> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className={styles.padding}>
                      <div className="row ">
                        <div className='col-lg-4 col-sm-12 '>
                          <label  className='fw-bold'>
                            Email
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          {
                            this.state.email
                          }
                          {/* <input type='text' className='form-control' value={this.state.email} disabled={true}></input> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className={styles.padding}>
                      <div className="row ">
                        <div className='col-lg-4 col-sm-12 '>
                          <label  className='fw-bold'>
                            Phone Number
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          {
                            this.state.phoneNumber
                          }
                          {/* <input type='text' className='form-control' value={this.state.phoneNumber} disabled={true}></input> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>
                    <div className={styles.padding}>
                      <div className="row ">
                        <div className='col-lg-4 col-sm-12 '>
                          <label  className='fw-bold'>
                            Pump Id
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          {
                            this.state.pumpId
                          }
                          {/* <input type='text' className='form-control' value={this.state.pumpId} disabled={true}></input> */}
                        </div>
                      </div>
                    </div>
                  </div>
                
                    <div className='col-lg-6 col-sm-12 '>
                    </div>
                  

                  <div className='col-lg-12 col-sm-12 '>
                    <div className={styles.padding}>
                      <div className="row ">
                        <div className='col-lg-2 col-sm-12 '>
                          <label className='fw-bold'>
                            Message
                          </label>
                        </div>
                        <div className='col-lg-10 col-sm-12 '>
                          {
                            this.state.message
                          }
                          {/* <textarea type="text" className='form-control' value={this.state.message} disabled={true}></textarea > */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-sm-12 '>

                  </div>
                </div>
              </div>
            </div>

        }
      </div>

    )
  }
}

ContactUsDetail.propTypes = {};

ContactUsDetail.defaultProps = {};

export default withRouter(ContactUsDetail);
