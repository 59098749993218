import React, { Component } from 'react';

import styles from './setting.module.scss';
import Loader from '../Loader/Loader';
import UserService from '../../Services/user-services'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { GrMailOption, GrFormLock } from "react-icons/gr";

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      form: {
        oldPassword: "",
        newpassword: "",
        password: ""

      },
      status:200,
      message:"",
      formErrors: {
        oldPassword: "",
        newpassword: "",
        password: ""
      },
      sucessPopup: false,
      sucessClass: true,
    };
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange = e => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};
    formObj = {
      ...form,
      [name]: value
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      if (name === "newpassword" || name === "password") {
        let refValue = this.state.form[
          name === "newpassword" ? "password" : "password"
        ];
        const errorMsg = this.validateField(name, value, refValue);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
        if (!errorMsg && refValue) {
          formErrorsObj.password = null;
          formErrorsObj.password = null;
        }
      } else {
        const errorMsg = this.validateField(
          name,
          name === "language" ? this.state.form["language"] : value
        );
        formErrorsObj = { ...formErrors, [name]: errorMsg };
      }
      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {

      case "oldPassword":
        if (!value) errorMsg = "Please enter Old Password.";
        break;
      case "newpassword":
        // refValue is the value of Confirm Password field
        if (!value) errorMsg = "Please enter New  Password.";
       
        break;
      case "password":
        // refValue is the value of Password field
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue)
          errorMsg = "New Password and Confirm Password does not match.";
        break;

      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      if (x === "newpassword" || x === "password") {
        refValue = form[x === "newpassword" ? "password" : "newpassword"];
      }
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
      return null
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    }else{
      this.setState({
        message:""
      })
      UserService.ChangePassword(form).then(
        response => {
        console.log('submit==>',response)
        if(response.data.status === 200){
          this.setState({
            status:response.data.status,
            message:response.data.message,
            formErrors: {
              oldPassword: "",
              newpassword: "",
              password: ""
            },
            sucessPopup:true

            
          })
        }
        else if(response.data.status === 400){
          this.setState({
            status:response.data.status,
            message:response.data.message,
            sucessPopup:true
            
          })
        }
        
        else{
          this.setState({
           
            sucessClass:false,
            sucessPopup:true,
            message:"Failed"
          },()=>{
            setTimeout(() => {
              this.setState({
                loader:true,
              })
              // this.props.navigate('/Equipment')
            }, 3000);
            
          })
        }
        },
        error => {
          console.log(error)
          setTimeout(() => {
            this.setState({
              loader:true,
            })
            // this.props.navigate('/Equipment')
          }, 3000);
        }
      );
    }
   
  };


  render() {
    const { form, formErrors } = this.state;
    return (
      <div className='Privacymain' >
        {
          this.state.loader === true ?
            <Loader /> :
            <div className='container'>
              <div className='row'>
                <div className='col-lg-4 col-sm-12'>
                  <div className={styles.column}>
                    <span className={styles.heading}>Change Password</span>

                    <div className="col-lg-12 col-sm-12">
                      {/* <div className={styles.message}>
                        {
                          this.state.status === 200 ? "" : "Old Password Does not Match"
                        }
                      
                        </div> */}
                      <div className="form-group">
                        <label>
                          OldPassword:<span className="asterisk">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          name="oldPassword"
                          value={form.oldPassword}
                          onChange={this.handleChange}
                          onBlur={this.handleChange}
                        />
                        {formErrors.oldPassword && (
                          <span className="err">{formErrors.oldPassword}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>
                          New Password:<span className="asterisk">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          name="newpassword"
                          value={form.newpassword}
                          onChange={this.handleChange}
                          onBlur={this.handleChange}
                        />
                        {formErrors.newpassword && (
                          <span className="err">{formErrors.newpassword}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>
                          Confirm Password:<span className="asterisk">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          value={form.password}
                          onChange={this.handleChange}
                          onBlur={this.handleChange}
                        />
                        {formErrors.password && (
                          <span className="err">{formErrors.password}</span>
                        )}
                      </div>
                      
                  <div className="form-group text-center">
                    <input
                      type="button"
                      className="btn btn-primary btncolor "
                      value="Submit"
                      onClick={this.handleSubmit}
                    />
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>

        }
        <div className={styles.toastMessage}>
          <ToastContainer className="w-100" >
              <Toast style={{ position: "absolute", right: 0 ,bottom:35 }} onClose={() => {
                this.setState({
                  sucessPopup: false
                })
              }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >
              <Toast.Body className={this.state.sucessClass === true ? styles.sucesss : styles.sucessFailed}>{this.state.message}</Toast.Body>
                {/* <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body> */}
              </Toast>
            </ToastContainer>
            </div>
      </div>

    );
  }
}

Setting.propTypes = {};

Setting.defaultProps = {};

export default Setting;
