export const NavItems = [
   {
      title:'Dashboard',
      img:'/imgs/dashboard.png',
      activeImgs:'/imgs/dashboardActive.png',
      url:'/Dashboard'
   },
   {
      title:'Equipments',
      img:'/imgs/equipment.png',
      activeImgs:'/imgs/equipmentActive.png',
      url:'/Equipment'
   },
   {
      title:'Manufacturer',
      img:'/imgs/brands.png',
      activeImgs:'/imgs/brandActive.png',
      url:'/Brand'
   },
   {
      title:'Catagories',
      img:'/imgs/sold.png',
      activeImgs:'/imgs/soldActive.png',
      url:'/Catagories'
   },
   {
      title:'Equipments Specfication',
      img:'/imgs/equipment.png',
      activeImgs:'/imgs/equipmentActive.png',
      url:'/Equipment-specfication'
   },
   {
      title:'Privacy',
      img:'/imgs/equipment.png',
      activeImgs:'/imgs/equipmentActive.png',
      url:'/Privacy'
   },
   {
      title:'Setting',
      img:'/imgs/equipment.png',
      activeImgs:'/imgs/equipmentActive.png',
      url:'/setting'
   },
   {
      title:'Contact Us',
      img:'/imgs/equipment.png',
      activeImgs:'/imgs/equipmentActive.png',
      url:'/Contact-Us'
   },
   
]