import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './Login.module.scss';
import Form from "react-validation/build/form";
import { GrMailOption, GrFormLock } from "react-icons/gr";
import AuthService from '../../Services/auth-services'
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import authHeader from '../../Services/auth-header';
import withRoutes from '../../Common/with-router'
// import BsFillEyeSlashFill from 'react-icons/bs';
import { BsFillEyeSlashFill } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
const required = value => {
  if (!value) {
    return (
      <p className="text-danger m-0 p-0 text-start" role="alert">
        Password is required. !
      </p>
    );
  }
};
const Emailrequired = value => {
  if (!value) {
    return (
      <p className="text-danger m-0 p-0 text-start" role="alert">
        Email is Required. !
      </p>
    );
  }
  else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ){
    return (
      <p className="text-danger m-0 p-0 text-start" role="alert">
       Please Valid Email Id
      </p>
    );
  }
    
  
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      error: "",
      imgShow:false,
    
      showPassword: false,
      
    };
  }
  componentDidMount(){
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth != null) {
      return window.location.href = '/Dashboard';
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value.trimStart()
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value.trimStart()
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        (data) => {
          console.log('RESPONSE==>', data)
          if (data.status === 200) {
            console.log("Page REdirect")

            this.props.navigate('/Dashboard')
            window.location.reload();
          } else {
            this.setState({
              error: data.message
            })
            console.log('page error')
          }

        },
        error => {
          console.log("Called Error==>", error)
          const resMessage =
            "Server not respond"

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: true
      });
    }
  }
  imgiconChage  =()=>{
    
    this.setState({
      imgShow:!this.state.imgShow,
      showPassword:!this.state.showPassword
    },()=>{
      console.log("Password==>",this.state.showPassword)
    })
  }
  render() {
    return (
      <Form
        onSubmit={this.handleLogin}
        ref={c => {
          this.form = c;
        }}>
        <div className={styles.body}>
          <div className={styles.backgroundImage}>
            <div className='container'>
              <div className='row vh-100'>
                <div className='col mt-auto mb-auto '>
                  <div className={styles.login}>
                    <div className='col'>
                      <img src="/imgs/logo.png" alt='imgLogo' className={styles.logo}></img>
                    </div>
                    <div className='col pt-3'>
                      <h3 className={styles.name}>Login to your Dashboard</h3>
                      <p className={styles.description}>Log in with your data that you entered during your registration</p>
                    </div>
                    <label className='text-danger'>
                      {this.state.error}
                    </label>
                    <div className='col pt-3'>
                      <div className={styles.email}>
                        <div className='row m-auto'>
                          <div className='col-lg-12 col-sm-12'>
                            <GrMailOption size={24} className={styles.fontIcon} />
                            <Input
                              type='text'
                              className="form-control"
                              placeholder="Email Address"
                              style={{ padding: "0.575rem 2.75rem" }}
                              value={this.state.username}
                              onChange={this.onChangeUsername}
                              validations={[Emailrequired]}></Input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col pt-4'>
                      <div className={styles.email}>
                        <div className='row m-auto'>
                          <div className='col-lg-12 col-sm-12'>
                            <GrFormLock size={32} className={styles.fontIconPassword} />
                            <Input
                              type={this.state.showPassword ? "text" : "password"}
                            
                            className="form-control" placeholder="Password" style={{ padding: "0.575rem 2.75rem" }} value={this.state.password}
                              onChange={this.onChangePassword}
                              validations={[required]}></Input>
                              <div className={styles.imgIcon}>
                                {
                                  this.state.imgShow ===  false ?  <BsFillEyeSlashFill onClick={this.imgiconChage}/> :  <AiFillEye onClick={this.imgiconChage}/>

                                }
                               
                              </div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col pt-5'>
                      <button
                        className='btn btn-primary' style={{ backgroundColor: "#19AB4F", width: "112px", height: "44px", borderColor: "rgba(31, 185, 252, 0.16)" }}
                      // disabled={this.state.loading}
                      >

                        <span>Login</span>
                      </button>
                      {this.state.message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {this.state.message}
                          </div>
                        </div>
                      )}
                      <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                          this.checkBtn = c;
                        }}
                      />
                      {/* <Button variant="primary">Primary</Button>{' '}  */}
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    )
  }


}


Login.propTypes = {};

Login.defaultProps = {};

export default withRoutes(Login);
