import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './Dashboard.module.scss';
// import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import UserService from '../../Services/user-services';
import authHeader from '../../Services/auth-header';
import Loader from '../Loader/Loader';
import AuthService from '../../Services/auth-services'
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      isDataLoaded: true,
      dashboardData: [],
      TotalEquipment:0,
      SoldEquipment:0,
      Brands:0
    };
  }
  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>",auth)
    if(auth == null){
      return  window.location.href = '/Login';
    }else{
    UserService.Dashboard().then(
      response => {
        // console.log("response==>", response)
        if (response.data.status === 200) {
          this.setState({
            TotalEquipment: response.data.data.TotalEquipment,
            SoldEquipment:response.data.data.SoldEquipment,
            Brands:response.data.data.Brands,
            dashboardData:response.data.data.equipment,
            isLoader:true
          })
        } else {
          this.setState({
            isLoader:false
           })
        }
      },
      error => {
        console.log(error)
        this.setState({
          isLoader:false
         },()=>{
          if(error.request.status === 401){
            AuthService.logout();
            return  window.location.href = '/Login';
          }
         })
      }
    );
    }
   

  }
  render() {
    return (
      <div>
        {
          this.state.isLoader === false ? 
          <Loader/>
          :
      <div className='main'>

        <div className='row m-0 p-0'>
          <div className='col-lg-4 col-sm-12 ' style={{ padding: "20px" }} >
            <div className={styles.dashboardShadow}>
            <Link to={'/Equipment'}>
              <div className='d-flex justify-content-between bg-white'>
                <div className={styles.paddingBox}>
                  <div className={styles.dashboardCard}>
                    Total Equipment
                  </div>
                  <div className={styles.dashboardCardAmount}>
                    {this.state.TotalEquipment}
                  </div>
                </div>
                <div className={styles.dashboardIcon}>
                  <div className={styles.dashboardEquipment}>
                    {/* <Link to={'/Equipment'}> */}

                      <img src='/imgs/equipmentDassboard.png' alt='' className={styles.imgEquipment}></img>
                    {/* </Link> */}
                  </div>

                </div>

              </div>
              </Link>
            </div>
          </div>
          <div className='col-lg-4 col-sm-12 ' style={{ padding: "20px" }} >
 
            <div className={styles.dashboardShadow}>
            <Link to={'/Equipment'}>
              <div className='d-flex justify-content-between bg-white'>
                <div className={styles.paddingBox}>
                  <div className={styles.dashboardCard}>
                    Sold Equipment
                  </div>
                  <div className={styles.dashboardCardAmount}>
                   {this.state.SoldEquipment}
                  </div>
                </div>
                <div className={styles.dashboardIcon}>
                  <div className={styles.dashboardSold}>
                 
                      <img src='/imgs/soldDasboard.png' alt='' className={styles.imgEquipment}></img>
                   

                  </div>

                </div>

              </div>
            </Link>
            </div>
          </div>
          <div className='col-lg-4 col-sm-12 ' style={{ padding: "20px" }} >
            <div className={styles.dashboardShadow}>
            <Link to={'/Brand'}>
              <div className='d-flex justify-content-between bg-white'>
                <div className={styles.paddingBox}>
                  <div className={styles.dashboardCard}>
                    Total Manufacture
                  </div>
                  <div className={styles.dashboardCardAmount}>
                    {this.state.Brands}
                  </div>
                </div>
                <div className={styles.dashboardIcon}>
                  <div className={styles.brand}>
                    {/* <Link to={'/Brand'}> */}
                      <img src='/imgs/equipmentDassboard.png' alt='' className={styles.imgEquipment}></img>
                    {/* </Link> */}
                  </div>

                </div>

              </div>
              </Link>
            </div>
          </div>
        </div>
        <div className='row m-0 p-0'>
          <div className={styles.equipmentData}>
            <div className='bg-white rounded-bottom rounded-top customer-border'>
              <div className='d-flex justify-content-between customer-border align-items-center p-3 flex-wrap'>
                <div className=''>
                  <h3 className={'dataHeading'}>
                    All Equipments
                  </h3>
                </div>
                <div>
                  {/* <input type='text' className='form-control' placeholder='Search'></input> */}
                </div>
              </div>
              <div>

                <div className={'customtable'}>
                  <table className="table tableWidth">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Equipment Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Year</th>
                        <th scope="col">Availability Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className=''>
                    {
                            this.state.dashboardData.map((data, index) => {
                              console.log('SUB',data)
                              return (
                                <tr key={index}>
                                <th className='border-0' scope="row">{index+1}</th>
                                <td className='border-0' >
                                <Link to={'/viewEquipment/' + data.id}>
                                  {data.name}
                                  </Link>
                                  </td>
                                <td className='border-0' > {data.BrandName}</td>
                                <td className='border-0' >{data.year}</td>
                                <td className='border-0' >
                                    {
                                      data.isSold === 0 ?
                                      <div className='soldStatusaccept'>
                                      <span className='soldSatussucess'>
                                      Available
                                      </span>
                                    </div>
                                        :
                                        <div className='soldStatusDecline'>
                                      <span className='soldSatusFailed'>
                                       Sold
                                      </span>
                                    </div>
                                    }

                                  </td>
                                <td className='border-0' >
                                <Link to={'/viewEquipment/' + data.id}>
                                    <img src='/imgs/detail.png' alt='' className='imgDetail'></img>
                                 </Link>
                                  {/* <img src='/imgs/detail.png' alt='' className={'imgDetail'}></img> */}
                                  </td>
                              </tr>
                              )
                            })
                          }
                  
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    }
      </div>


    )
  }
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
