import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './EquipmentSpecficationEdit.module.scss';
import Button from 'react-bootstrap/Button';
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom';
import authHeader from '../../Services/auth-header';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { AiFillDelete } from "react-icons/ai";
import UserService from '../../Services/user-services'
import withRoutes from '../../Common/with-router'
import axios from 'axios';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AuthService from '../../Services/auth-services'
class EquipmentSpecficationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      setDetail: false,
      sucessPopup: false,
      uploadFileFormat: false,
      imgaeUploadPercantage: 0,
      isImageUploadDone: true,
      completeImageUpload: [],
      btndisable: true,
      fileStatus: false,
      fileUploadStatus: false,
      Categories: [
        {
          id: 1,
          name: "Putzmeister"
        },
        {
          id: 2,
          name: "Schwing"
        },
        {
          id: 3,
          name: "Other"
        }
      ],
      CategoriyId: "",
      inputs: {
        name: "",

      },
      errors: {
        name: "",
      },
      showIcon: true,
      dummy: [],
      message: "failed",
      open:false

    };
  }
  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      let params = {
        id: this.props.params.id
      }
      console.log("PROPS==>", params)
      UserService.eqipmentSpecficationGet(params).then(
        response => {

          if (response.data.status === 200) {
            console.log("response==>", response.data.data.EquipmentCategory)
            this.setState({
              fileStatus: true,
              CategoriyId: response.data.data.EquipmentCategory,
              inputs: {
                name: response.data.data.Name,
              },
              // dummy:[this.state.dummy,{uri:response.data.data.Link}],
              completeImageUpload: [...this.state.completeImageUpload, { uri: response.data.data.Link, fileName: "File" }]
              // completeImageUpload:[...this.state.completeImageUpload.uri,response.data.data.Link]
            }, () => {
              console.log("response==>", this.state.completeImageUpload)
              this.setState({
                loader: true
              })
            })

          } else {
            this.setState({
              loader: false
            })
          }
        },
        error => {
          this.setState({
            loader: false,
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          })
        }
      );
    }


  }
  validate = (name, value) => {

    switch (name) {
      case "name":
        if (!value) {
          return "name is Required";
        } else {
          return "";
        }


      default: {
        return "";
      }
    }
  }

  handleUserInput = e => {
    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: this.validate(e.target.name, e.target.value.trimStart())
      },
      inputs: {
        ...this.state.inputs,
        [e.target.name]: e.target.value.trimStart()
      }
    });
  };
  handleEdit = e =>{
     const { inputs } = this.state;
    e.preventDefault();
    let validationErrors = {};
    Object.keys(inputs).forEach(name => {
      const error = this.validate(name, inputs[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    } else {
      console.log("Image Count==>")
      if (this.state.completeImageUpload.length === 0) {
        this.setState({
          message:"Please Add Pdf Equipment file",
          sucessPopup: true,
        })
        // alert("Please Add Pdf")
      } else {
        let params = {
          id: this.props.params.id,
          name: this.state.inputs.name,
          link: this.state.completeImageUpload[0].uri,
          categroies: this.state.CategoriyId
        }
        UserService.eqipmentSpecficationEdit(params).then(
          response => {

            if (response.data.status === 200) {
            
              console.log("response==>", response)
              this.setState({
                inputs: {
                  name: ""
                },
                message: response.data.message
              }, () => {
                setTimeout(() => {
                  this.props.navigate('/Equipment-specfication')
                }, 1800);
                
              
              })

            } else {
              this.setState({
                loader:false,
                message: response.data.message
              });
            }
          },
          error => {
            console.log(error)
            this.setState({
              loader:false
            });
          }
        );

        
       
      }
      
    }
  }
  handleDelete = e=>{
    let params = {
      id: this.props.params.id,
    }
    this.setState({
      open:false
    })
    UserService.eqipmentSpecficationDelete(params).then(
      response => {

        if (response.data.status === 200) {
          this.props.navigate('/Equipment-specfication')
          console.log("response==>", response)
          this.setState({
            inputs: {
              name: "",
      
            },
            errors: {
              name: "",
            },
            CategoriyId:"",
            sucessPopup: true,
            completeImageUpload:[],
            message: response.data.message
          }, () => {
            setTimeout(() => {
              this.props.navigate('/Equipment-specfication')
            }, 1800);
            
          
          })

        } else {
          this.setState({
            loader:false
          })
        }
      },
      error => {
        this.setState({
          loader:false
        })
      }
    );
  }
  onImageChange = event => {
    console.log('PUSH==>', event.target.value)
    let text = event.target.value
    const myArray = text.split("\\").pop().split(".");
    console.log('PUSH==>', myArray[1])
    if (myArray[myArray.length-1].toLowerCase() === 'pdf') {

      this.setState({
        uploadFileFormat: false
      })
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        let formData = new FormData();
        this.setState({ btndisable: false })
        formData.append("file", img);
        let params = {
          folder: "Equipment specfication",
          file: img
        }
        this.setState({
          fileStatus: true,
          btnStatus: true
        })
        return axios.post
          ('https://api.concretepumpingdepot.com//api/v1//common/uploadFile', params, {

            onUploadProgress: progressEvent =>


              this.setState({ imgaeUploadPercantage: progressEvent.progress * 100 }),
            // console.log('progres----',progressEvent.progress),
            headers: {
              "Content-Type": "multipart/form-data",

            }
          }).then(
            response => {
              console.log('REPONSE==>', response)
              if (response.data.status === 200) {

                this.setState((state) => {
                  return {
                    completeImageUpload: [{ uri: response.data.data.locationUrl, fileName: myArray[0] + "." + myArray[1], isUpload: 1 }, ...state.completeImageUpload]
                  };
                }, () => {
                  console.log('PUSH==>', this.state.completeImageUpload)
                })

                this.setState({ btnStatus: false })

              }
            })
      }
      console.log("FileFo")
    } else {

      this.setState({
        uploadFileFormat: true
      })
      console.log("dd")
    }
  }
  deleteImage = (event) => {
    this.setState({ fileStatus: false })
    this.setState({ completeImageUpload: this.state.completeImageUpload.filter((data, index) => index !== event) })
  }
  onDocumentLoadSuccess = (event) => {
    this.setState({
      setNumPages: 1
    })
    console.log("Event==>", event)
  }
  handleChange = (event) => {
    console.log('E', event.target.value)

    this.setState({ CategoriyId: parseInt(event.target.value) }, () => {
      console.log("VALUEOFSETALL==>", this.state.CategoriyId)
    })


  };
  handleCloseDialog = ()=>{
    this.setState({
      open:false
    })
  }
  handleOpenDialog  =()=>{
      this.setState({
        open:true
      })
  }
  render() {
    const { errors } = this.state;
    return (
      <div className='main'>
        {
          this.state.loader === false ?
            <Loader /> :
            <div className='container-fluid'>
              <div className='row m=0 p-0'>
                <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                  <div className=''>
                    <Link to={'/Equipment-specfication'}>


                      {/* <h3 className={ "dataHeading"} style={{margin:'0px',padding:'0px'}}> */}
                      <span className='dataHeading'>
                        <span>
                          <img src='https://placing-booms-content.s3.amazonaws.com/icon/icon_1683009649845.png' className='imgarrow' alt=''></img>
                        </span>
                        Equipment Specfication
                      </span>
                    </Link>
                    {/* </h3> */}
                  </div>

                </div>
              </div>
              <div className={styles.main}>
                <div className='row'>

                  <div className='col-lg-12 col-sm-12'>
                    <div className='row'>
                      <div className='col-lg-2 col-sm-12 colsLabel'>
                        <label className={styles.labelCatagories}>
                          Name
                        </label>
                      </div>
                      <div className='col-lg-3 col-sm-12 '>
                        <input
                          type={'text'}
                          name="name"
                          className={
                            'form-control'}
                          onChange={event => this.handleUserInput(event)}
                          value={this.state.inputs.name}
                        ></input>
                        <span className="text-danger">{errors.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12 col-sm-12 py-4'>
                    <div className='row'>
                      <div className='col-lg-2 col-sm-12 colsLabel'>
                        <label className={styles.labelCatagories}>
                          Catagories
                        </label>
                      </div>
                      <div className='col-lg-3 col-sm-12 '>
                        <FormControl
                          className={styles.firstTextbox}
                        >
                          <Select
                            className={styles.colorWhite}
                            value={this.state.CategoriyId}
                            onChange={this.handleChange}
                            displayEmpty
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: "#03C03C",
                                    margin: '0px 10px',
                                    borderRadius: "4px",
                                    color: 'white',

                                  },
                                  "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "#03C03C",
                                    margin: '0px 10px',
                                    borderRadius: "4px",
                                    color: 'white'
                                  },
                                  "& .MuiMenuItem-root.Mui-selected:hover": {
                                    backgroundColor: "#03C03C",
                                    margin: '0px 10px',
                                    borderRadius: "4px",
                                    color: 'white'
                                  }
                                }
                              }
                            }}
                          >
                            {
                              this.state.Categories.map((data, index) => {
                                // console.log('SUB',data)
                                return (
                                  <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
  
                  <div className={styles.productdescription}>
                    <h3 className={styles.fileUpload}>
                    Equipment Pdf Files
                    </h3>
                    <div className='row m-0 p-0'>
                      <div className='col-lg-6 col-sm-12 '>
                        <div>
                          <div className={styles.imagepadding}>
                            {
                              this.state.uploadFileFormat === true ?
                                "File Format Not Supported" : ""
                            }
                          </div>
                          <div className={styles.uploadOuterV1}>

                            <div className={styles.dragBox} >
                              <img src='/imgs/uploadIcon.png' className={styles.uploadIcon} alt=''></img>
                              <input type="file" onChange={this.onImageChange} disabled={this.state.fileStatus} accept="application/pdf" />
                              <span className={styles.imgtextCenter}>
                                Drag File To upload
                              </span>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-12 '>

                        <div className={styles.imgeUploadPaadding}>
                          <div className='row'>
                            {
                              this.state.completeImageUpload.map((data, index) => {
                                console.log('INDEX==>', index)
                                // console.log('Equipment',data)
                                return (

                                  <div className='col-lg-12 p-3' key={index}>
                                    <div className='row'>
                                      {/* <div className='imagepadding'>
                                        <h3>Uploading</h3>
                                      </div> */}
                                      <div className='col-lg-2 col-sm-3 '>
                                        <a href={data.uri} target="_blank"
                                          rel="noreferrer">
                                          <img src='https://placing-booms-content.s3.us-east-2.amazonaws.com/ProductImage/ProductImage_1684670141216.jpg' className={styles.imgWidth} alt=""></img>

                                        </a>

                                      </div>
                                      <div className='col-lg-8 col-sm-7'>
                                        <span>{data.fileName}</span>
                                        <ProgressBar variant='danger' now={100} className={styles.imageProgress} />
                                        <span>Completed</span>
                                      </div>
                                      <div className='col-lg-2 col-sm-3 imgDeleteIcon'>
                                        <span>
                                          {

                                            this.state.showIcon === true ? <AiFillDelete onClick={() => this.deleteImage(index)} className={styles.closeIcon} /> : ""
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                  
                   
                    <div className='d-flex justify-content-center m-2'>
                      <div style={{padding:"0px 40px 0px 0px"}}>
                        <button className='btn btn-success px-4 py-2'
                          onClick={this.handleEdit}
                          disabled={this.state.btnStatus === true ? true : false}
                        >Update </button>
                      </div>
                      <div>
                        <button className='btn btn-danger px-4 py-2'
                        onClick={this.handleOpenDialog}

                        >Delete</button>
                      </div>
                    </div>
                    <ToastContainer className="w-100" >
                    <Toast style={{ position: "absolute", right: 0 }} onClose={() => {
                      this.setState({
                        sucessPopup: false
                      })
                    }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >

                      <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                  <Dialog
        open={this.state.open}
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure delete this equipment specfication"}
        </DialogTitle>
     
        <DialogActions>
          <Button onClick={this.handleCloseDialog}>Cancel</Button>
          <Button onClick={this.handleDelete} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
                  </div>
                            
                </div>
              </div>

            </div>
        }
      </div>
    )
  }
}
EquipmentSpecficationEdit.propTypes = {};

EquipmentSpecficationEdit.defaultProps = {};

export default withRoutes(EquipmentSpecficationEdit);
