import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './EquipmentSpecfication.module.scss';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import UserService from '../../Services/user-services';
import Button from 'react-bootstrap/Button';
import Pagination from "react-js-pagination";
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom';
import authHeader from '../../Services/auth-header';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AuthService from '../../Services/auth-services'
class EquipmentSpecfication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      id: 0,
      pageIndex: 1,
      pageSize: 10,
      pageRange: 3,
      totalRecords: 0,
      loader: false,
      pageRefresh: false,
      searchKey: "",
      EquipmentList: [],
      content: "",
      setDetail: false,
      sucessPopup: false,
      message: 'failed',
      open: false,
      userId: 0,
      disable: false,
      delete: false,
      btnStatusDisable: false,


    };
  }
  componentDidMount() {
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      let params = {
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize
      }
      UserService.eqipmentSpecficationList(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              EquipmentList: response.data.data.EqipmentSpecficationList,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,
              loader: true,
              totalRecords: response.data.data.totalRecords,
            })
          } else {
            this.setState({
              loader:false
            })
          }
        },
        error => {
          this.setState({
            loader:false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          })
        }
      );
    }


  }
  handlePageChange = pageNumber => {
    // console.log("Count===>",this.state.count)
    this.setState({
      count: 0
    })

    if (pageNumber !== this.state.pageIndex) {
      let params = {
        pageIndex: pageNumber,
        pageSize: this.state.pageSize
      }
      UserService.eqipmentList(params).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              EquipmentList: response.data.data.EquipmentList,
              pageIndex: response.data.data.pageIndex,
              pageSize: response.data.data.pageSize,

              totalRecords: response.data.data.totalRecords,
            })
          } else {

          }
        },
        error => {
          this.setState({
            loader:false
          });
        }
      );
    }

  }
    // disable

    handleCloseDisable = () => {
      this.setState({
        disable: false
      })
    }
    handleOpenDisbale = (e, disable) => {
      console.log("Disable==>", disable)
      this.setState({
        disable: true,
        userId: e,
        btnStatusDisable: !disable
      })
  
    }
    handleDisable = () => {
      let params = {
        id: this.state.userId,
        status: this.state.btnStatusDisable
      }
      UserService.DisableEquipmentDisable(params).then(
        response => {
          if (response.data.status === 200) {
            this.setState({
              sucessPopup: true,
              message: response.data.message,
              disable: false
            }, () => {
              let params = {
                pageIndex: this.state.pageIndex,
                pageSize: this.state.pageSize
              }
              UserService.eqipmentSpecficationList(params).then(
                response => {
                  console.log("response==>", response)
                  if (response.data.status === 200) {
                    this.setState({
                      EquipmentList: response.data.data.EqipmentSpecficationList,
                      pageIndex: response.data.data.pageIndex,
                      pageSize: response.data.data.pageSize,
                      loader: true,
                      totalRecords: response.data.data.totalRecords,
                    })
                  } else {
                    this.setState({
                      loader:false
                    })
                  }
                },
                error => {
                  this.setState({
                    loader:false
                  })
                }
              );
  
            })
          } else {
            this.setState({
              sucessPopup: true,
              message: response.data.message,
              disable: false
            }, () => {
  
  
  
            })
          }
          // console.log("response==>", response)
  
        },
        error => {
          console.log(error)
          this.setState({
            isLoader: false,
            sucessPopup: true,
            message: "Server Not Response",
            disable: false
          });
        }
      );
    }
  
    // delete
    handleCloseDelete = () => {
      this.setState({
        delete: false
      })
    }
    handleOpenDelete = (e) => {
  
      console.log("VALUE==>", e)
      this.setState({
        delete: true,
        userId: e,
  
      }, () => {
  
      })
  
  
    }
  
    handleDelete = () => {
      let params = {
        id: this.state.userId,
      }
      UserService.eqipmentSpecficationDelete(params).then(
        response => {
          if (response.data.status === 200) {
            this.setState({
              sucessPopup: true,
              message: response.data.message,
              delete: false
            }, () => {
             
              let params = {
                pageIndex: this.state.pageIndex,
                pageSize: this.state.pageSize
              }
              UserService.eqipmentSpecficationList(params).then(
                response => {
                  console.log("response==>", response)
                  if (response.data.status === 200) {
                    this.setState({
                      EquipmentList: response.data.data.EqipmentSpecficationList,
                      pageIndex: response.data.data.pageIndex,
                      pageSize: response.data.data.pageSize,
                      loader: true,
                      totalRecords: response.data.data.totalRecords,
                    })
                  } else {
                    this.setState({
                      loader:false
                    })
                  }
                },
                error => {
                  this.setState({
                    loader:false
                  })
                }
              );
  
  
            })
          } else {
            this.setState({
              sucessPopup: true,
              message: response.data.message,
              disable: false
            }, () => {
  
  
  
            })
          }
          // console.log("response==>", response)
  
        },
        error => {
          console.log(error)
          this.setState({
            isLoader: false,
            sucessPopup: true,
            message: "Server Not Response",
            disable: false
          });
        }
      );
    }
  render() {
    return (
      <div className='main'>
        {
          this.state.loader === false ?
            <Loader /> :
            <div>
              <div className='row m-0 p-0'>
                <div className='bg-white customer-border rounded-bottom rounded-top m-0 p-0 bg-white'>
                  <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                    <div className=''>
                      <h3 className={"dataHeading"} style={{ margin: '0px', padding: '0px' }}>
                        Equipment specfication List
                      </h3>
                    </div>
                    <div className={'position-relative'}>
                      <span className={styles.plusIcon}>
                        <img src='/imgs/plus.png' className={'plus-icon'} alt=''></img>
                      </span>
                      <Link to={'/Add-Equipment-specfication'} className='btnColorSuccess'>
                        Add Equipment specfication
                      </Link>
                      {/* <button className='btnColorSuccess' onClick={this.handleShow}>Add Equipment</button> */}
                    </div>
                  </div>
                  <div>
                    <div className='customtable'>
                      <table className="table tableWidth">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col"> Name</th>
                            <th scope="col">Equipment Catagories</th>
                            <th scope="col">Pdf</th>
                            <th scope="col">Action</th>
                            <th scope="col">Disable</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.EquipmentList.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <th className='border-0' >{index + 1}</th>
                                  <td className='border-0'>{data.Name}</td>
                                  <td className='border-0'>{

                                    data.EquipmentCategory === '1' ? "Putzmeister" : data.EquipmentCategory === "2" ? "Schwing" : "Other"



                                  }</td>
                                  <td className='border-0'>
                                    <a href={data.Link} target="_blank"
                                      rel="noreferrer">
                                      <img src='https://placing-booms-content.s3.us-east-2.amazonaws.com/ProductImage/ProductImage_1684670141216.jpg' className={styles.imgWidth} alt=""></img>

                                    </a>
                                  </td>

{/* 
                                  <td className='border-0'>
                                  <Link to={'/Edit-Equipment-specfication/' + data.id}>
                                    <img src='/imgs/detail.png' alt='' className='imgDetail'></img>
                                 </Link>
                                  </td> */}
                                  <td className='border-0'>
                                    <ul className='navlistIcon'>
                                      <li>
                                        <div className='edit'>
                                        <Link to={'/Edit-Equipment-specfication/' + data.id}>
                                            <img src='/imgs/edit.png' alt='' className='img-fluid'></img>
                                          </Link>
                                        </div>
                                      </li>
                                      <li>
                                        <div className='delete' onClick={() => { this.handleOpenDelete(data.id) }}>
                                          <img src='/imgs/delete.png' alt='' className='img-fluid'></img>
                                        </div>
                                      </li>
                                     
                                    </ul>
                                  </td>
                                  <td  className='border-0'>
                                 
                                        <div className={"disableActive"} onClick={() => { this.handleOpenDisbale(data.id, data.isDisable) }}   >
                                          
                                        {
                                            data.isDisable === 0 ? 
                                            <img src='/imgs/disable.png' alt='' className='img-fluid' ></img>
                                            :
                                            <img src='/imgs/enable.png' alt='' className='img-fluid' ></img>
                                          }
                                          {/* <img src='/imgs/disable.png' alt='' className='img-fluid' ></img> */}
                                        </div>
                                     
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      <div className='row'>
                        <div className='col-lg-3 m-auto p-2'>
                          {
                            this.state.totalRecords === 0 ?
                              <span className='fw-bold'>No Data Found</span>
                              :
                              <Pagination
                                // disableInitialCallback={true}
                                itemClass="page-item"
                                linkClass="page-link"
                                // activeClass={styles.disabled}
                                activePage={this.state.pageIndex}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalRecords}
                                pageRangeDisplayed={this.state.pageRange}
                                onChange={this.handlePageChange.bind(this)}
                                activeLinkClass={styles.pageColor}
                              // hideDisabled={true}
                              // hideNavigation={true}
                              />
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div>
                {/* Toaster */}
                <ToastContainer className="my-5 mx-2" position={'bottom-end'}>
            <Toast onClose={() => {
              this.setState({
                sucessPopup: false
              })
            }} show={this.state.sucessPopup} delay={1500} autohide  >

              <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
            </Toast>
          </ToastContainer>
          <div className='disableDialog'>
          <Dialog
            open={this.state.disable}
            onClose={this.handleCloseDisable}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure disable"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={this.handleCloseDisable}>Cancel</Button>
              <Button onClick={this.handleDisable} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

        </div>
        <div className='deleteDialog'>
          <Dialog
            open={this.state.delete}
            onClose={this.handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure delete"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={this.handleDelete} autoFocus>
                Ok
              </Button>
              <Button onClick={this.handleCloseDelete}>Cancel</Button>
            </DialogActions>
          </Dialog>

        </div>
                </div>
            </div>
        }

      </div>
    )
  }
}

EquipmentSpecfication.propTypes = {};

EquipmentSpecfication.defaultProps = {};

export default EquipmentSpecfication;
