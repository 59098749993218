import axios from 'axios';
import { Component } from 'react';
import authHeader from './auth-header';


// const API_URL = "http://localhost:3004/api/v1/"
const API_URL = "https://api.concretepumpingdepot.com//api/v1/"
// console.log = () => {}
class UserService extends Component {

  static Dashboard(){

    return axios.get(API_URL + '/admin/Dashboard', { headers: authHeader() })
  }
 
  static eqipmentList(params){
    return axios.post(API_URL + '/admin/equipmentList',params,{headers:authHeader()})
  }

  static bransList (params){
    console.log(params)
    return axios.post(API_URL + '/admin/brandsList', params,{headers:authHeader()});
  }

  static categoryList (params){
    console.log(params)
    return axios.post(API_URL + '/admin/categoryList', params,{headers:authHeader()});
  }
  static allquipment(){
    return axios.get(API_URL + '/admin/allEquipment',{headers:authHeader()})
  }
  static brands(){
    return axios.get(API_URL + '/admin/brands',{headers:authHeader()})
  }

  static AddEquipment(params){
    console.log('PARAM==>',params)
    return axios.post(API_URL + '/admin/addEquipment',params,{headers:authHeader()})
  }

  static addBrands (params){
    console.log(params)
    return axios.post(API_URL + '/admin/addBrands', params,{headers:authHeader()});
  }

  static GetBrandId(params){
    console.log(params)
    return axios.post(API_URL + '/admin/getBrandId', params,{headers:authHeader()});
  }
  static DeleteBrand(params){
    console.log(params)
    return axios.put(API_URL + '/admin/deletebrand', params,{headers:authHeader()});
  }
  static UpdateBrand(params){
    console.log(params)
    return axios.put(API_URL + '/admin/editbrand', params,{headers:authHeader()});
  }

  static addCategory (params){
    console.log(params)
    return axios.post(API_URL + '/admin/addCategory', params,{headers:authHeader()});
  }

  static CategoryId(params){
    console.log(params)
    return axios.post(API_URL + '/admin/getCategoryId', params,{headers:authHeader()});
  }
  static EditCategory(params){
    console.log(params)
    return axios.put(API_URL + '/admin/editCategory', params,{headers:authHeader()});
  }
  static DeleteCategory(params){
    console.log(params)
    return axios.put(API_URL + '/admin/deleteCategory', params,{headers:authHeader()});
  }

  static eqipmentSpecficationList(params){
    return axios.post(API_URL + '/admin/EquipmentSpecficationList',params,{headers:authHeader()})
  }

  static eqipmentSpecficationAdd(params){
    return axios.post(API_URL + '/admin/AddEquipmentSpecfication',params,{headers:authHeader()})
  }

  static eqipmentSpecficationGet(params){
    return axios.post(API_URL + '/admin/GetEquipmentSpecfication',params,{headers:authHeader()})
  }


  static eqipmentSpecficationEdit(params){
    return axios.put(API_URL + '/admin/EditEquipmentSpecfication',params,{headers:authHeader()})
  }

  static eqipmentSpecficationDelete(params){
    return axios.put(API_URL + '/admin/DeleteEquipmentSpecfication',params,{headers:authHeader()})
  }
  static EditPrivacy(params){
    return axios.post(API_URL + '/admin/EditPrivacy',params,{headers:authHeader()})
  }

  static Privacy(){
    return axios.get(API_URL + '/common/GetPrivacy',{headers:authHeader()})
  }

  static GetEquipment(params){
    return axios.post(API_URL + '/admin/getEquipment',params,{headers:authHeader()})
  }  

  static DeleteEquipment (params){
    return axios.post(API_URL + '/admin/DeleteEquipment',params,{headers:authHeader()})
  }

  static EditEquipment (params){
    return axios.put(API_URL + '/admin/EditEquipment',params,{headers:authHeader()})
  }
  static DisableEquipment (params){
    return axios.post(API_URL + '/admin/equipmentDisable',params,{headers:authHeader()})
  }
  static DisableManufacture (params){
    return axios.post(API_URL + '/admin/manufactureDisable',params,{headers:authHeader()})
  }
  static DisableCategories (params){
    return axios.post(API_URL + '/admin/categoriesDisable',params,{headers:authHeader()})
  }
  static DisableEquipmentDisable (params){
    return axios.post(API_URL + '/admin/equipmentSpecifictaionDisable',params,{headers:authHeader()})
  }
  static ChangePassword (params){
    return axios.post(API_URL + '/admin/changePassword',params,{headers:authHeader()})
  }








  static advance(){
    return axios.get(API_URL + '/commom/allEquipmentAdvance',)
  }
  static recentProduct(){
    return axios.post(API_URL + '/commom/recentAddedProduct',)
  }
  static productDetail(){
    return axios.post(API_URL + '/commom/productDetail',)
  }
  static similarProduct(){
    return axios.post(API_URL + '/commom/similarProduct',)
  }
  static formSubmit(form){
    return axios.post(API_URL + '/commom/FormSubmit',form)
  }
  static uploadImage(params) {
    
    return axios.post(API_URL + '/common/uploadFile', {
      params
    }, {
      onUploadProgress: progressEvent => console.log('progres----',progressEvent),
      headers: {
        "Content-Type": "multipart/form-data",

      }
    });
  }

  static contactUs (params){
    console.log(params)
    return axios.post(API_URL + '/admin/contactUs', params,{headers:authHeader()});
  }  
  static contactUsDetail (params){
    console.log(params)
    return axios.post(API_URL + '/admin/ContactUsDetail', params,{headers:authHeader()});
  } 
  static updateEQuipmentProirity (params){
    console.log(params)
    return axios.post(API_URL + '/admin/updateEquipmentOrder', params,{headers:authHeader()});
  }  
  

  static DeleteContactUs(params){
    console.log(params)
    return axios.put(API_URL + '/admin/ContactUsDelete', params,{headers:authHeader()});
  }





}


export default UserService;


