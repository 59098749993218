import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './EditEquipment.module.scss';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import UserService from '../../Services/user-services'
// import { BsFillArrowUpSquareFill } from "react-icons/bs";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { AiFillDelete } from "react-icons/ai";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Link } from 'react-router-dom';
import authHeader from '../../Services/auth-header';
import WithRouter from '../../Common/with-router';
import Loader from '../Loader/Loader'
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import AuthService from '../../Services/auth-services'

class EditEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      allquipment: 0,
      allboomEquipment: 0,
      advance: 0,
      equipment: [],
      advanceData: [],
      recentProduct: [],
      uploadImage: [],
      completeImageUpload: [],
      imgaeUploadPercantage: 0,
      showIcon: false,
      imageUpload: false,
      isImageUploadDone: true,
      fileName: "",
      isimaageUploadComplete: false,
      imageFormat: true,
      show: false,
      cardImg: "",
      checkboxed: false,
      isPrority:false,
      inputs: {
        name: "",
        amount: '',
        pumpYear: "",
        pumpMake: "",
        pumpModel: "",
        pumpHours: "",
        pumpSerial: "",
        pumpSection: "",
        pumpFoldStyle: "",
        pumpRadioRemote: "",
        pumpProportional:"",
        pumpCondition: "",
        pumpNotes: "",


        truckMake: "",
        truckModel: "",
        truckVin: "",
        truckEngine: "",
        truckHours: "",
        truckMiles: "",
        truckTransmission: "",
        truckManual: "",
        truckNotes: "",
      },
      errors: {
        name: "",
        amount: '',
        pumpYear: "",
        pumpMake: "",
        pumpModel: "",
        pumpHours: "",
        pumpSerial: "",
        pumpSection: "",
        pumpFoldStyle: "",
        pumpRadioRemote: "",
        pumpProportional:"",
        pumpCondition: "",
        pumpNotes: "",


        truckMake: "",
        truckModel: "",
        truckVin: "",
        truckEngine: "",
        truckHours: "",
        truckMiles: "",
        truckTransmission: "",
        truckManual: "",
        truckNotes: "",

      },
      btnStatus: false,
      sucessPopup: false,
      message: "failed",
      open:false,
      truckInfoChecked:true




    };
    this.handleEdit = this.handleEdit.bind(this);
  }
  componentDidMount() {
    console.log("Path URl==>", this.props.params.id)
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      UserService.allquipment().then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              equipment: response.data.data.CategoryList,
              // allquipment: response.data.data.CategoryList.length ? response.data.data.CategoryList[0]?.id : ''
            },
              () => {
                console.log("first==>", this.state.allquipment.length)


              })

          } else {
            this.setState({
              isLoader: false
            })
          }
        },
        error => {
          console.log(error)
          this.setState({
            isLoader: false
          },()=>{
            if(error.request.status === 401){
              AuthService.logout();
              return  window.location.href = '/Login';
            }
          });
        }
      );

      UserService.brands().then(
        response => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            this.setState({
              advanceData: response.data.data,
              // advance:response.data.data[0]?.id

            }, () => {

            })
          } else {

          }
        },
        error => {
          console.log(error)
        }
      );
      let params = {
        id: parseInt(this.props.params.id)
      }

      UserService.GetEquipment(params).then(
        response => {
          console.log(response)
          if (response.data.status === 200) {
            this.setState({
              allquipment: response.data.data[0].categoryId,
              advance: response.data.data[0].brandId,
              checkboxed: response.data.data[0].isSold === 1 ? true :false,
              allboomEquipment: response.data.data[0].subCategoryId,
              showIcon:true,
              truckInfoChecked:response.data.data[0].truckInfoStatus === 1 ? true :false,
              isPrority:response.data.data[0].PriorityId === 1 ?true :false
            },
              () => {
                console.log("STATE==>", this.state)
                response.data.data[0].images.map((data, index) => {
                  console.log('MAPIMAGES==>',data)
                  this.setState((state) => {
                    var filename = data.split('/').pop()
                   
                    return {
                      completeImageUpload: [{ uri: data, fileName: filename, isUpload: 1 }, ...state.completeImageUpload]
                    };
                  })
                  return null
                })
               
                this.setState(prevState => {
                  let inputs = Object.assign({}, prevState.input);  // creating copy of state variable jasper
                  inputs.name = response.data.data[0].name;
                  inputs.amount = response.data.data[0].amount;
              
                  inputs.pumpMake = response.data.data[0].pumpInfo.make
                  inputs.pumpModel = response.data.data[0].pumpInfo.model
                  inputs.pumpYear = response.data.data[0].pumpInfo.year
                  inputs.pumpHours = response.data.data[0].pumpInfo.hours
                  inputs.pumpSerial = response.data.data[0].pumpInfo.serialNumber
                  inputs.pumpSection = response.data.data[0].pumpInfo.section
                  inputs.pumpFoldStyle = response.data.data[0].pumpInfo.foldStyle
                  inputs.pumpRadioRemote = response.data.data[0].pumpInfo.radioRemote
                  inputs.pumpProportional = response.data.data[0].pumpInfo.proportional
                  inputs.pumpCondition = response.data.data[0].pumpInfo.Conditions
                  inputs.pumpNotes = response.data.data[0].pumpInfo.notes
                 
                  inputs.truckMake = response.data.data[0].truckInfo.make
                  inputs.truckModel = response.data.data[0].truckInfo.model
                  inputs.truckVin = response.data.data[0].truckInfo.vin
                  inputs.truckEngine = response.data.data[0].truckInfo.engine
                  inputs.truckHours = response.data.data[0].truckInfo.hours
                  inputs.truckMiles = response.data.data[0].truckInfo.miles
                  inputs.truckTransmission = response.data.data[0].truckInfo.transmission
                  inputs.truckManual = response.data.data[0].truckInfo.manual
                  inputs.truckNotes = response.data.data[0].truckInfo.notes
               


                  // : "",

                  return { inputs };
                }, () => {
                  this.setState({
                    isLoader: true
                  })
                })
              }
            )


          } else {
            this.setState({
              isLoader: false
            });
          }
        },
        error => {
          console.log(error)
          this.setState({
            isLoader: false
          });
        }
      )

    }
  }
  handleChange = (event) => {
    console.log('Evv', event.target.value)
    console.log("VALUEOFSETALL==>", this.state.equipment)
    this.state.equipment.forEach((item) => {
      if (item.id === event.target.value) {
        console.log("this is selected item", item);
        this.setState({ allboomEquipment: item.catagoryList[0]?.id }, () => {
          console.log("DATE==>", this.state.allboomEquipment)
        })
      }
    })
    this.setState({ allquipment: parseInt(event.target.value) })

  };
  handleChangeV1 = (event) => {
    console.log('E', event.target.value)

    this.setState({ allboomEquipment: parseInt(event.target.value) },()=>{
      console.log("VALUEOFSETALL==>",this.state.allboomEquipment)
    })


  };
  advanceEquipment = (event) => {
    console.log('E', event.target.value)
    this.setState({ advance: parseInt(event.target.value) })

  };
  onImageChange = event => {
    
    console.log('PUSH==>', event.target.value)

    let text = event.target.value
    const myArray = text.split("\\").pop().split("." );
    console.log('PUSH==>', myArray[1])
    if (myArray[myArray.length-1].toLowerCase() === 'jpg' || myArray[myArray.length-1].toLowerCase() === 'png' || myArray[myArray.length-1].toLowerCase() === 'jpeg') {
      this.setState({ imageFormat: true })
      console.log("Image Format  Supported")
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        console.log("Imaeg==>",event.target.files)
        let formData = new FormData();
        this.setState({ btndisable: false })
        formData.append("file", img);
        let params = {
          folder: "ProductImage",
          file: img
        }
        console.log(params)
        if (this.state.completeImageUpload.length > 8) {
          console.log("TENUPLOAD")
          this.setState({ isimaageUploadComplete: true })
        } else {
          console.log("TENUPLOADNOT")
        }
        this.setState({ imageUpload: true, isImageUploadDone: false, fileName: myArray[0] + "." + myArray[1] }, async () => {
          console.log('CALLEDDATA==>', this.state.isImageUploadDone)
          return axios.post
            ('https://api.concretepumpingdepot.com/api/v1//common/uploadFile', params, {

              onUploadProgress: progressEvent =>


                this.setState({ imgaeUploadPercantage: progressEvent.progress * 100 }),
              // console.log('progres----',progressEvent.progress),
              headers: {
                "Content-Type": "multipart/form-data",

              }
            }).then(
              response => {
                console.log('REPONSE==>', response)
                if (response.data.status === 200) {

                  this.setState((state) => {
                    console.log("STATE==>",state)
                    let filename = response.data.data.locationUrl.split('/').pop()


                    return {
                      completeImageUpload: [{ uri: response.data.data.locationUrl, fileName: filename, isUpload: 1 }, ...state.completeImageUpload]
                    };
                  }, (data) => {
                    console.log('PUSH==>', this.state.completeImageUpload)
                  })

                  this.setState({ showIcon: true, isImageUploadDone: true })
                  this.setState({ imageUpload: false })
                }
              })
        })


      }
    } else {
      this.setState({ imageFormat: false })
      console.log("Image Format Not Supported")
    }
  }
  deleteImage = (event) => {
    this.setState({ isimaageUploadComplete: false })
    this.setState({ completeImageUpload: this.state.completeImageUpload.filter((data, index) => index !== event) })
  }
  handleClose = () => this.setState({ show: false });
  handleShow = (index) => {
    // console.log(index)
    this.setState({ cardImg: index })
    this.setState({ show: true });
  }
  validate = (name, value) => {

    switch (name) {
      case "name":
        if (!value) {
          return "name is Required";
        } else {
          return "";
        }
      case "amount":
        if (!value) {
          return "amount is Required";
        }
        else if (
          !/^[0-9]\d*(\.\d+)?$/.test(
            value
          )
        ){
          console.log("First Return if==>")
          return "Invalid amount"
        }
        else {
          return "";
        }
      // case "pumpyear":
      //   if (!value) {
      //     return "Pump Year Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpmake":
      //   if (!value) {
      //     return " pumpmake is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpmodel":
      //   if (!value) {
      //     return "pumpmodel is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumphours":
      //   if (!value) {
      //     return "pumphours  is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpmiles":
      //   if (!value) {
      //     return "pumpmiles is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpengine":
      //   if (!value) {
      //     return "pumpengine  is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumptransmission":
      //   if (!value) {
      //     return "pumptransmission is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpvin":
      //   if (!value) {
      //     return "pumpvin  is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpcondition":
      //   if (!value) {
      //     return "pumpcondition is Required";
      //   } else {
      //     return "";
      //   }
      // case "pumpnotes":
      //   if (!value) {
      //     return "pumpnotes  is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckyear":
      //   if (!value) {
      //     return "truckyear is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckbrands":
      //   if (!value) {
      //     return "truckbrands  is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckmake":
      //   if (!value) {
      //     return "truckmake is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckmodel":
      //   if (!value) {
      //     return "truckmodel  is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckpumphrs":
      //   if (!value) {
      //     return "truckpumphrs is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckboomLength":
      //   if (!value) {
      //     return "truckboomLength  is Required";
      //   } else {
      //     return "";
      //   }
      // case "trucksections":
      //   if (!value) {
      //     return "trucksections is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckfoldStyle":
      //   if (!value) {
      //     return " truckfoldStyle is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckradioRemote":
      //   if (!value) {
      //     return "truckradioRemote is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckProptional":
      //   if (!value) {
      //     return "truckProptional  is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckSerial":
      //   if (!value) {
      //     return " truckSerial is Required";
      //   } else {
      //     return "";
      //   }
      // case "truckCondition":
      //   if (!value) {
      //     return "truckCondition is Required";
      //   } else {
      //     return "";
      //   }
      // case "trucknotes":
      //   if (!value) {
      //     return "trucknotes  is Required";
      //   } else {
      //     return "";
      //   }

      default: {
        return "";
      }
    }
  }

  handleUserInput = e => {
    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: this.validate(e.target.name, e.target.value.trimStart())
      },
      inputs: {
        ...this.state.inputs,
        [e.target.name]: e.target.value.trimStart()
      }
    });
  };

  handleEdit = e => {
    const { inputs } = this.state;
    e.preventDefault();
    let validationErrors = {};
    Object.keys(inputs).forEach(name => {
      const error = this.validate(name, inputs[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    } else {
      console.log("Image Count==>")
      if (this.state.completeImageUpload.length === 0) {
        this.setState({
          message:"At Least One Image is Uploaded",
          sucessPopup: true,
        
         
        })
        // alert("AtLeast  One Image can added")
      } else {
        let data = {
          pumpInfo: {
            make: inputs.pumpMake,
            model: inputs.pumpModel,
            year: inputs.pumpYear,
            hours: inputs.pumpHours,
            serialNumber: inputs.pumpSerial,
            section: inputs.pumpSection,
            foldStyle: inputs.pumpFoldStyle,
            radioRemote: inputs.pumpRadioRemote,
            proportional:inputs.pumpProportional,
            condition: inputs.pumpCondition,
            notes: inputs.pumpNotes
          },
          truckInfo: {
            make: inputs.truckMake,
            model: inputs.truckModel,
            vin: inputs.truckVin,
            engine: inputs.truckEngine,
            hours: inputs.truckHours,
            miles: inputs.truckMiles,
            transmission: inputs.truckTransmission,
            manual: inputs.truckManual,
            notes: inputs.truckNotes,
           
          },
          amount: inputs.amount,
          name: inputs.name,
          image: this.state.completeImageUpload.map((item) => {
            return item.uri
          }
          ),

          categoryId: this.state.allquipment,
          subCategoryId: this.state.allboomEquipment,
          brandId: this.state.advance,
          isSold: this.state.checkboxed,
          id: parseInt(this.props.params.id),
          truckInfoChecked:this.state.truckInfoChecked,
          isPrority:this.state.isPrority
        }
        console.log('newPrint---', data)
        UserService.EditEquipment(data).then(
          response=>{
            if (response.data.status === 200) {
              this.setState({
                sucessClass:true,
                sucessPopup: true,
                message: response.data.message
              },()=>{
                setTimeout(() => {
                  this.props.navigate('/Equipment')
                }, 1800);
                // this.props.navigate('/Equipment')
              })
            } else {
              this.setState({
                sucessPopup: true,
                message: response.data.message
              })
            }
            console.log(response)
          },error=>{
            this.setState({
              sucessPopup: true,
              message: "server not respond"
            })
            console.log(error)
          }
        )
       

      }
      // window.alert("subit success");
    }

  };
  handleCheckBox = () => {
    this.setState({
      checkboxed: !this.state.checkboxed,
      // subCatBtn:!this.state.subCatBtn
    }, () => {

    });
  }
  handleCheckBoxV1 = () => {
    this.setState({
      isPrority: !this.state.isPrority,
      // subCatBtn:!this.state.subCatBtn
    }, () => {

    });
  }
  handleDelete  =()=>{
    let params = {
      id: parseInt(this.props.params.id)
    }
    UserService.DeleteEquipment(params).then(
      response => {
        if(response.data.status === 200){
          this.setState({
            sucessPopup: true,
            message: response.data.message,
            open:false
          },()=>{
           
            setTimeout(() => {
              this.props.navigate('/Equipment')
            }, 1800);
            
          })
        }else{
          this.setState({
            sucessPopup: true,
            message: response.data.message
          },()=>{
           
            setTimeout(() => {
              this.props.navigate('/Equipment')
            }, 1800);
            
          })
        }
        // console.log("response==>", response)
       
      },
      error => {
        console.log(error)
        this.setState({
          isLoader: false,
          sucessPopup: true,
          message: "Server Not Respons"
        });
      }
    );
    console.log("DEletedApi==>")
  }
  handleCloseDialog = ()=>{
    this.setState({
      open:false
    })
  }
  handleOpenDialog  =()=>{
      this.setState({
        open:true
      })
  }
    handleTruckInfoCheckBox = () => {
    this.setState({
      truckInfoChecked: !this.state.truckInfoChecked,
      // subCatBtn:!this.state.subCatBtn
    }, () => {

    });
  }
  render() {
    const { errors } = this.state;
    return (
      <div>
        {
          this.state.isLoader === false ?
            <Loader />
            :
            <div className='container-fluid'>
              <div className='row m=0 p-0'>
                <div className='d-flex justify-content-between customer-border align-items-center py-3 px-4 flex-wrap '>
                  <div className=''>
                    <Link to={'/Equipment'}>


                      {/* <h3 className={ "dataHeading"} style={{margin:'0px',padding:'0px'}}> */}
                      <span className='dataHeading'>
                        <span>
                          <img src='https://placing-booms-content.s3.amazonaws.com/icon/icon_1683009649845.png' className='imgarrow' alt=''></img>
                        </span>
                        Manufacturer
                      </span>
                    </Link>
                    {/* </h3> */}
                  </div>

                </div>
              </div>
              <div className='row py-5'>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                        Name
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      <input
                        type={'text'}
                        name="name"
                        className={
                          'form-control'}
                        onChange={event => this.handleUserInput(event)}
                        value={this.state.inputs.name}
                      ></input>
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-sm-12'>
                      <div className='row'>
                        <div className='col-lg-4 col-sm-12 colsLabel'>
                          <label className={styles.labelCatagories}>
                            Category
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          <FormControl
                            className={styles.firstTextbox}
                          >
                            <Select
                              className={styles.colorWhite}
                              value={this.state.allquipment}
                              onChange={this.handleChange}
                              displayEmpty
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    "& .MuiMenuItem-root.Mui-selected": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white',

                                    },
                                    "& .MuiMenuItem-root:hover": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white'
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                      backgroundColor: "#03C03C",
                                      margin: '0px 10px',
                                      borderRadius: "4px",
                                      color: 'white'
                                    }
                                  }
                                }
                              }}
                            >
                              {
                                this.state.equipment.map((data, index) => {
                                  // console.log('SUB',data)
                                  return (
                                    <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-lg-6 col-sm-12 m-0 p-0'>
                      <div className='row'>
                        <div className='col-lg-4 col-sm-12 colsLabel'>
                          <label className={styles.labelCatagories}>
                            Sub Category
                          </label>
                        </div>
                        <div className='col-lg-6 col-sm-12 '>
                          {
                            this.state.equipment.map((item, index) => {
                              console.log('SUBIT==>', item)
                              return (
                                <div key={index}>
                                  {

                                    item.id === this.state.allquipment ?
                                      <div key={index}>
                                        <FormControl sx={{ m: 1, minWidth: 120, }}
                                          className={styles.firstTextbox}
                                        >
                                          <Select
                                            className={styles.colorWhite}
                                            value={this.state.allboomEquipment}
                                            onChange={this.handleChangeV1}
                                            MenuProps={{
                                              PaperProps: {
                                                sx: {
                                                  "& .MuiMenuItem-root.Mui-selected": {
                                                    backgroundColor: "#03C03C",
                                                    margin: '0px 10px',
                                                    borderRadius: "4px",
                                                    color: 'white',

                                                  },
                                                  "& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#03C03C",
                                                    margin: '0px 10px',
                                                    borderRadius: "4px",
                                                    color: 'white'
                                                  },
                                                  "& .MuiMenuItem-root.Mui-selected:hover": {
                                                    backgroundColor: "#03C03C",
                                                    margin: '0px 10px',
                                                    borderRadius: "4px",
                                                    color: 'white'
                                                  }
                                                }
                                              }
                                            }}
                                            displayEmpty
                                          >
                                            {
                                              item.catagoryList.map((data, index) => {
                                                // console.log('Equipment inEquipment', data.id)
                                                // this.setState(({
                                                //   allboomEquipment:data.id
                                                // }))
                                                return (
                                                  <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                                )
                                              })
                                            }
                                          </Select>
                                        </FormControl>
                                      </div>


                                      : ""
                                  }
                                </div>
                              )

                              // return <></>;
                            })
                          }
                        </div>
                      </div>
                    </div> */}
  <div className='col-lg-6 col-sm-12 m-0 p-0'>
                  <div className='row'>
                    {/* <div className='col-lg-4 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                       Sub Category
                      </label>
                    </div> */}
                    
                    <div>
                      {
                        // this.state.equipment.catagoryList.length===0?"":
                        this.state.equipment.map((item, index) => {
                         
                          return (
                            item.catagoryList.length === 0 ?"":
                            <div key={index}>
                              {
                              
                                item.id === this.state.allquipment ?
                                  <div key={index}>
                                    <div className='row'>
                                    <div className='col-lg-4 col-sm-12 colsLabel'>
                                      <label className={styles.labelCatagories}>
                                      Sub Category
                                      </label>
                                    </div>
                                    <div className='col-lg-6 col-sm-12 '>
                                    <FormControl sx={{ m: 1, minWidth: 120, }}
                                      className={styles.firstTextbox}
                                    >
                                      <Select
                                        className={styles.colorWhite}
                                        value={this.state.allboomEquipment}
                                        onChange={this.handleChangeV1}
                                        MenuProps={{
                                          PaperProps: {
                                            sx: {
                                              "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white',

                                              },
                                              "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white'
                                              },
                                              "& .MuiMenuItem-root.Mui-selected:hover": {
                                                backgroundColor: "#03C03C",
                                                margin: '0px 10px',
                                                borderRadius: "4px",
                                                color: 'white'
                                              }
                                            }
                                          }
                                        }}
                                        displayEmpty
                                      >
                                        {
                                         
                                          item.catagoryList.map((data, index) => {
                                            // console.log('Equipment inEquipment', data.id)
                                            // this.setState(({
                                            //   allboomEquipment:data.id
                                            // }))
                                            return (
                                              <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                            )
                                          })
                                        }
                                      </Select>
                                    </FormControl>
                                  </div>
                                  </div>
                                  </div>


                                  : ""
                              }
                            </div>
                          )

                          // return <></>;
                        })
                      }
                    </div>
                  </div>
                </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                      Manufacturer
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      <FormControl sx={{ m: 1, minWidth: 120 }}
                        className={styles.firstTextbox}
                      >
                        <Select
                          className={styles.colorWhite}
                          value={this.state.advance}
                          onChange={this.advanceEquipment}
                          displayEmpty
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: "#03C03C",
                                  margin: '0px 10px',
                                  borderRadius: "4px",
                                  color: 'white',

                                },
                                "& .MuiMenuItem-root:hover": {
                                  backgroundColor: "#03C03C",
                                  margin: '0px 10px',
                                  borderRadius: "4px",
                                  color: 'white'
                                },
                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                  backgroundColor: "#03C03C",
                                  margin: '10px 10px',
                                  borderRadius: "4px",
                                  color: 'white'
                                }
                              }
                            }
                          }}
                        >
                          {
                            this.state.advanceData.map((data, index) => {
                              // console.log('Equipment',data)
                              return (
                                <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>

                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                        Price
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      <input
                        type={'text'}
                        name="amount"
                        className={
                          'form-control'}
                        onChange={event => this.handleUserInput(event)}
                        value={this.state.inputs.amount}
                      ></input>
                      <span className="text-danger">{errors.amount}</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                      Sold
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                      {/* <input
                        type="checkbox"
                        checked={this.state.checkboxed}
                        ref="complete"
                        onChange={this.handleCheckBox}
                        style={{ margin: "10px 0" }}
                      /> */}
                       <Switch
                        checked={this.state.checkboxed}
                        onChange={this.handleCheckBox}
                        // disabled={true}
                        // inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-lg-2 col-sm-12 colsLabel'>
                      <label className={styles.labelCatagories}>
                      isPriority
                      </label>
                    </div>
                    <div className='col-lg-3 col-sm-12 '>
                    
                       <Switch
                        checked={this.state.isPrority}
                        onChange={this.handleCheckBoxV1}
                       
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.productdescription}>
                  <hr />
                  <div className='d-flex justify-content-between pb-5'>
              <div>
              <h3 className={styles.mainheading}>
                Specfication
              </h3>
              </div>
              <div>
              <span className={styles.mainheading}>
                Truck Info
              </span>
              <Switch
                        checked={this.state.truckInfoChecked}
                        onChange={this.handleTruckInfoCheckBox}
                        // disabled={true}
                        // inputProps={{ 'aria-label': 'controlled' }}
                      />
              </div>
              <div></div>
              <div></div>
            </div>
                    <div className='row justify-content-start'>
                      <div className='col-md-5 m-0 pt-3 col-sm-12'>
                        <div className='row justify-content-start m-2'>
                          <h3 className={styles.descriptionHeading}>
                            Pump Info
                          </h3>
                          <div className='row'>
                            <div className='col-lg-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Make:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpMake"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpMake}

                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpMake}</span>

                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Model:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpModel"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpModel}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpModel}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Year:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpYear"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpYear}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpYear}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Pump Hours:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpHours"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpHours}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpHours}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Serial Number:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpSerial"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpSerial}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpSerial}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Sections:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpSection"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpSection}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpSection}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Fold Style:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpFoldStyle"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpFoldStyle}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpFoldStyle}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Radio Remote:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpRadioRemote"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpRadioRemote}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpRadioRemote}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Proportional:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpProportional"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpProportional}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.pumpProportional}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Condition:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpCondition"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpCondition}
                                  onChange={this.handleUserInput}
                                ></input>
                                <span className="text-danger">{errors.pumpCondition}</span>
                              </div>

                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Notes:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="pumpNotes"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.pumpNotes}
                                  onChange={this.handleUserInput}
                                ></input>
                                <span className="text-danger">{errors.pumpNotes}</span>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-5 m-0 pt-3 col-sm-12'>
                        <div className='row justify-content-start m-2'>
                          <h3 className={styles.descriptionHeading}>
                            Truck Info
                          </h3>
                          <div className='row'>
                            <div className='col-lg-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Make:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckMake"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckMake}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckMake}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Model:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>

                                <input
                                  type={'text'}
                                  name="truckModel"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckModel}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckModel}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Vin:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckVin"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckVin}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckVin}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Engine:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckEngine"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckEngine}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckEngine}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Hours:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckHours"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckHours}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckHours}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Miles:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckMiles"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckMiles}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckMiles}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Transmission:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckTransmission"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckTransmission}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckTransmission}</span>
                              </div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Manual / Automatic:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckManual"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckManual}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckManual}</span>

                              </div>
                            </div>                                                     
                             <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeaturesheading}>Notes:</div>
                            </div>
                            <div className='col-lg-6 com-md-6 m-0  col-sm-12'>
                              <div className={styles.descriptionfeatures}>
                                <input
                                  type={'text'}
                                  name="truckNotes"
                                  className={
                                    'form-control'
                                  }
                                  value={this.state.inputs.truckNotes}
                                  onChange={event => this.handleUserInput(event)}
                                ></input>
                                <span className="text-danger">{errors.truckNotes}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                </div>
                <hr />
                <div className={styles.productdescription}>
                  <h3 className={styles.fileUpload}>
                    Equipment Gallery
                  </h3>
                  <div className='row'>
                    {
                      this.state.isimaageUploadComplete === true ?
                        <div className='col-lg-6 col-sm-12'>

                          <div className={styles.imageborder}>
                            <div className='form-group'>
                              <div className='col-lg-12 imagepadding'>

                                <div className={styles.uploadOuterV1}>
                                  <span className={styles.dragBox} >
                                    Maximum  10 images upload
                                    <img src='/imgs/uploadIcon.png' className={styles.uploadIcon} alt=''></img>
                                    {/* <BsFillArrowUpSquareFill className={styles.uploadIcon} /> */}

                                    <input type="file" onChange={this.onImageChange} disabled={true} />
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className='col-lg-6 col-sm-12'>
                          <div className='text-danger fw-bold text-center'>
                            {
                              this.state.imageFormat === true ? "" : 'Image format not supported'
                            }
                          </div>
                          <div className={styles.imageborder}>
                            <div className='form-group'>
                              <div className='col-lg-12 imagepadding'>
                                <div className={styles.uploadOuterV1}>
                                  <span className={styles.dragBox} >
                                    Drag File To upload
                                    <img src='/imgs/uploadIcon.png' className={styles.uploadIcon} alt=''></img>
                         
                                    <input type="file" onChange={this.onImageChange} disabled={this.state.imageUpload} 
                                    onClick={(e)=> {e.currentTarget.value = null}} 
                                    multiple={true}
                                    accept="image/png, image/jpeg"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    }
                    <div className='col-lg-6 col-sm-12'>
                      <div className='form-group'>
                        <div className='col-lg-12 imagepadding'>
                          {/* <h5 className='fw-bold'>File Upload Status</h5> */}
                          <div className='row'>
                            {
                              this.state.isImageUploadDone === true ? "" :
                                <div className='col-lg-12 p-3' >
                                  <div className='row'>
                                    <div className='col-lg-2 col-sm-3 border '>
                                    </div>
                                    <div className='col-lg-8 col-sm-7'>
                                      <span>{this.state.fileName}</span>
                                      <ProgressBar animated now={this.state.imgaeUploadPercantage} className={styles.imageProgress} />
                                      <span>{this.state.imgaeUploadPercantage.toFixed(0)}%</span>
                                    </div>
                                  </div>
                                </div>

                            }
                          </div>
                          <div className='row'>
                            {
                              this.state.completeImageUpload.map((data, index) => {
                                console.log('INDEX==>', index)
                                // console.log('Equipment',data)
                                return (
                                  <div className='col-lg-12 p-3' key={index}>
                                    <div className='row'>
                                      <div className='col-lg-2 col-sm-3 '>
                                        <img src={data.uri} className={styles.imgWidth} alt='' onClick={() => this.handleShow(data.uri)} />
                                      </div>
                                      <div className='col-lg-8 col-sm-7'>
                                        <span>{data.fileName}</span>
                                        <ProgressBar variant='danger' now={100} className={styles.imageProgress} />
                                        <span>Completed</span>
                                      </div>
                                      <div className='col-lg-2 col-sm-3 imgDeleteIcon'>
                                        <span>
                                          {

                                            this.state.showIcon === true ? <AiFillDelete onClick={() => this.deleteImage(index)} className={styles.closeIcon} /> : ""
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                            <Modal show={this.state.show} onHide={this.handleClose}

                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <Modal.Header closeButton>

                                <Modal.Title className={styles.popuptitle}>IMAGE </Modal.Title>

                              </Modal.Header>

                              <Modal.Body className={styles.popupMessage}>

                                <img src={this.state.cardImg} alt='' className='w-100'></img>
                              </Modal.Body>



                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div className='row'>
                  <div className='d-flex justify-content-center m-2'>
                    <div style={{ padding: "0px 40px 0px 0px" }}>
                      <button className='btn btn-success px-4 py-2'
                        onClick={this.handleEdit}
                        disabled={this.state.btnStatus === true ? true : false}
                      >Update</button>
                    </div>
                    <div>
                      <button className='btn btn-danger px-4 py-2'
                        onClick={this.handleOpenDialog}

                      >Delete</button>
                    </div>
                  </div>
                </div>
                <div className='row'>

                  <ToastContainer className="w-100" >
                    <Toast style={{ position: "absolute", right: 0 }} onClose={() => {
                      this.setState({
                        sucessPopup: false
                      })
                    }} show={this.state.sucessPopup} delay={1500} autohide position={'bottom-end'}  >

                      <Toast.Body style={{ backgroundColor: "#00ad4d", color: 'white' }}>{this.state.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                </div>
              </div>
              <div>
              <Dialog
        open={this.state.open}
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure delete this equipment"}
        </DialogTitle>
     
        <DialogActions>
          <Button onClick={this.handleCloseDialog}>Cancel</Button>
          <Button onClick={this.handleDelete} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
              </div>        
            </div>

        }
      </div>
    )
  }
}

EditEquipment.propTypes = {};

EditEquipment.defaultProps = {};

export default WithRouter(EditEquipment);
